import cn from 'classnames'

import { InputProps, InputSize, InputShape, InputAppearance } from "./input-types"

/**
 * Shortcut for getting classes for components that extend input
 * @param props Input Props
 */
export function getInputClasses(props: InputProps) {
  return cn({
    // size
    '-sm': props.size === InputSize.SMALL,
    '-lg': props.size === InputSize.LARGE,
    // shape
    '-rounded': props.shape === InputShape.ROUNDED,
    '-circular': props.shape === InputShape.CIRCULAR,
    '-squared': props.shape === InputShape.SQUARED,
    // variant
    '-outline': props.appearance === InputAppearance.OUTLINE,
    '-fill': props.appearance === InputAppearance.FILL,
    '-minimal': props.appearance === InputAppearance.MINIMAL,
  })
}
