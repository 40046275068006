import { Intent } from '../types/intent';

/**
 * Return CSS class for intent.
 */
export function getIntentClass(intent?: Intent) {
  if (intent == null) {
    return '-default';
  }
  return `-${intent.toLowerCase()}`;
}
