import { HTMLMotionProps } from 'framer-motion'

import { BaseProps } from '../../../types'
import { Enum } from '../../../library'

export const OverlayStatus = Enum({
  OPENING: 'opening',
  OPEN: 'open',
  CLOSING: 'closing',
  CLOSED: 'closed',
})
export type OverlayStatus = Enum<typeof OverlayStatus>

export interface OverlayLifecycleProps {
  /**
   * Lifecycle method invoked just before the CSS _close_ transition begins on
   * a child. Receives the DOM element of the child being closed.
   */
  onClosing?: () => void

  /**
   * Lifecycle method invoked just after the CSS _close_ transition ends but
   * before the child has been removed from the DOM. Receives the DOM element
   * of the child being closed.
   */
  onClosed?: () => void

  /**
   * Lifecycle method invoked just after mounting the child in the DOM but
   * just before the CSS _open_ transition begins. Receives the DOM element of
   * the child being opened.
   */
  onOpening?: () => void

  /**
   * Lifecycle method invoked just after the CSS _open_ transition ends.
   * Receives the DOM element of the child being opened.
   */
  onOpened?: () => void
}

export interface OverlayableProps extends OverlayLifecycleProps {
  /**
   * Whether the overlay should acquire application focus when it first opens.
   * @default true
   */
  autoFocus?: boolean

  /**
   * Keeps items outside of the overlay from becoming focused.
   * @default false
   */
  enforceFocus?: boolean

  /**
   * Whether pressing the `esc` key should invoke `onClose`.
   * @default true
   */
  isClosedOnEscape?: boolean

  /**
   * Whether the overlay blocks content below. This is always true if backdrop is enabled.
   * @default false
   */
  isBlocking?: boolean

  /**
   * If `true` and `usePortal={true}`, the `Portal` containing the children is created and attached
   * to the DOM when the overlay is opened for the first time; otherwise this happens when the
   * component mounts. Lazy mounting provides noticeable performance improvements if you have lots
   * of overlays at once, such as on each row of a table.
   * @default true
   */
  isLazy?: boolean

  /**
   * Whether overflowed content will cause a scroll. Ignored unless `isBlocking`
   * or `hasBackdrop` is true.
   * @default false
   */
  isScrollable?: boolean

  /**
   * A callback that is invoked when user interaction causes the overlay to close, such as
   * clicking on the overlay or pressing the `esc` key (if enabled).
   *
   * Receives the event from the user's interaction, if there was an event (generally either a
   * mouse or key event). Note that, since this component is controlled by the `isOpen` prop, it
   * will not actually close itself until that prop becomes `false`.
   */
  onClose?: (event?: React.SyntheticEvent<HTMLElement>) => void

  /**
   * Space-delimited string of class names applied to the `Portal`, if used.
   */
  portalClassName?: string

  /**
   * The container element into which the overlay renders its contents, when `usePortal` is `true`.
   * This prop is ignored if `usePortal` is `false`.
   * @default document.body
   */
  portalContainer?: HTMLElement

  /**
   * Prevent the body from scrolling while the overlay is open. Applies best effort
   * CSS attributes to the body. Works in the majority of browsers, but not guaranteed.
   */
  preventBodyScroll?: boolean

  /**
   * Whether the overlay should be wrapped in a `Portal`, which renders its contents in a new
   * element attached to `portalContainer` prop.
   *
   * This prop essentially determines which element is covered by the backdrop: if `false`,
   * then only its parent is covered; otherwise, the entire page is covered (because the parent
   * of the `Portal` is the `<body>` itself).
   *
   * Set this prop to `false` on nested overlays (such as `Dialog` or `Popover`) to ensure that they
   * are rendered above their parents.
   * @default true
   */
  usePortal?: boolean

  /**
   * Sets a delay between closing the overlay and actually removing the child
   * props from the dom so you can perform intro / exit animations
   */
  transitionDuration?: number | [number, number]
}

export interface BackdropProps {
  /** CSS class names to apply to backdrop element. */
  backdropClassName?: string

  /** HTML props for the backdrop element. */
  backdropProps?: HTMLMotionProps<'div'>

  /**
   * Whether clicking outside the overlay element (either on backdrop when present or on document)
   * should invoke `onClose`.
   * @default true
   */
  isClosedOnOutsideClick?: boolean

  /**
   * Whether a container-spanning backdrop element should be rendered behind the contents.
   * @default true
   */
  hasBackdrop?: boolean
}

export interface OverlayProps extends OverlayableProps, BackdropProps, BaseProps {
  /**
   * Whether the overlay (and its children) are visible.
   */
  isOpen: boolean
}


export interface OverlayState {
  hasEverOpened?: boolean
  status: OverlayStatus
}
