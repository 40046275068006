import React from 'react'
import cn from 'classnames'

import { BaseProps } from '../../../../types'
import { Enum } from '../../../../library'
import { marginClass } from '../../../../library/spacing'

export const FontFamily = Enum({
  SERIF: 'serif',
  SANS: 'sans',
  MONO: 'mono',
  INHERIT: 'inherit',
})
export type FontFamily = Enum<typeof FontFamily>

interface HeadingProps extends BaseProps {
  family?: FontFamily
  margin?: number[]
  tagName?: keyof JSX.IntrinsicElements
  size?: number
}

export const Heading: React.FC<HeadingProps> = (props) => {
  const TagName = props.tagName

  const classNames = cn(
    'heading',
    {
      [`f--${props.size}`]: props.size,
      [`t--${props.family}`]: props.family !== 'inherit',
    },
    props.className,
    props.margin ? marginClass(props.margin) : '',
  )

  return (
    <TagName
      className={classNames}
      style={props.style}
      children={props.children}
    />
  )
}

Heading.defaultProps = {
  family: FontFamily.INHERIT,
  tagName: 'div',
}
