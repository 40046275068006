import React from 'react'
import cn from 'classnames'

import { Tooltip } from '../../../tooltip'
import { BaseProps } from '../../../../types'
import { Icon } from '../../../icon'

export const fieldClasses = {
  MAIN: 'field',
  LABEL: 'field__label',
  DESCRIPTION: 'field__description',
  ERROR: 'field__error',
  CONTROLS: 'controls',
  CONTROL: 'control',
  HELP: 'field__help',
}

export interface FieldProps extends BaseProps {
  /** Descriptive text shown below or on top of the controls */
  description?: string

  /** Where to description is placed */
  descriptionPosition?: 'top' | 'bottom'

  /** Text to show when there is an error on the field */
  errorText?: string | string[]

  /** Help text is placed in a tooltip next to the label */
  helpText?: string

  /** A label for the field */
  label?: string
}

export const Field: React.FC<FieldProps> = (props) => {
  const className = cn(fieldClasses.MAIN, props.className)

  const renderErrors = () => {
    if (!props.errorText) return null
    const errorArray = Array.isArray(props.errorText)
      ? props.errorText
      : [props.errorText]
    return errorArray
      .filter(error => typeof error === 'string')
      .map((error, index) => (
        <span key={index} className={fieldClasses.ERROR}>
          <Icon name="Close" />
          {error}
        </span>
      ))
  }

  const renderHelp = () => {
    if (!props.helpText) return null

    return (
      <Tooltip
        content={props.helpText}
        targetTagName="span"
        targetClassName={fieldClasses.HELP}
      >
        <Icon name="InfoFill" />
      </Tooltip>
    )
  }

  return (
    <div className={className}>
      {props.label || props.helpText ? (
        <label className={fieldClasses.LABEL}>
          {props.label} {renderHelp()}
          {props.label}
        </label>
      ) : null}

      {props.description && props.descriptionPosition === 'top' ? (
        <div className={fieldClasses.DESCRIPTION}>{props.description}</div>
      ) : null}

      {props.children}

      {props.errorText ||
        (props.description && props.descriptionPosition === 'bottom') ? (
          <div className={fieldClasses.DESCRIPTION}>
            {renderErrors()}
            {props.description && props.descriptionPosition === 'bottom' ? (
              <span>{props.description}</span>
            ) : null}
          </div>
        ) : null}
    </div>
  )
}

Field.defaultProps = {
  descriptionPosition: 'bottom',
}
