import React from 'react'

import { Enum } from '../../../../library'

/** Valid HTML input types */
export type InputTypes =
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week'

// Size
export const InputSize = Enum({
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
})
export type InputSize = Enum<typeof InputSize>

// Shape
export const InputShape = Enum({
  SQUARED: 'squared',
  CIRCULAR: 'circular',
  ROUNDED: 'rounded'
})
export type InputShape = Enum<typeof InputShape>

// Variant
export const InputAppearance = Enum({
  MINIMAL: 'minimal',
  OUTLINE: 'outline',
  FILL: 'fill'
})
export type InputAppearance = Enum<typeof InputAppearance>


/**
 * Standard Control Properties (button, input, select...)
 */
export interface InputProps {
  /** General size of the control. Modifies text size */
  size?: InputSize

  /** How the corners of the control are shaped */
  shape?: InputShape

  /** Stylistic appearance of the control. */
  appearance?: InputAppearance
}

export type InputUpdate<V = any, E = React.ChangeEvent<HTMLElement>> = (
  value: V,
  field: string,
  e?: E,
) => void
