import React from 'react'
import cn from 'classnames'
import { motion, Variants } from 'framer-motion'

import { Enum, MaxWidthType } from '../../../library'
import { Box } from '../../box'
import { Close } from '../../close'
import { Overlay, OverlayableProps, BackdropProps } from '../../overlay'
import { AttachedDialog } from './attached-dialog'
import { BaseProps, IntentProps, PositionType } from '../../../types'

const dialogClasses = {
  MAIN: 'dialog',
  CONTENT: 'dialog__content',
  HEADER: 'dialog__header',
  BODY: 'dialog__body',
  FOOTER: 'dialog__footer',
}

export const DialogSizeType = Enum({
  AUTO: 'auto',
  FULLWIDTH: 'fullwidth',
  ...MaxWidthType,
})
export type DialogSizeType = Enum<typeof DialogSizeType>

export const DialogPositionType = Enum({ ...PositionType, CENTER: 'center' })
export type DialogPositionType = Enum<typeof DialogPositionType>

export interface DialogProps
  extends BaseProps,
  IntentProps,
  OverlayableProps,
  BackdropProps {
  /** Whether the dialog is open (visible) */
  isOpen: boolean

  /**
   * The display poisition of the popup
   *
   * @default DialogPositionType.CENTER
   */
  position?: DialogPositionType

  /**
   * The size (width) of the dialog. This is an extension of the 'max-width' sizes.
   * Thus, on smaller screens it will shrink to fill the window.
   *
   * @default DialogSizeType.AUTO
   */
  size?: DialogSizeType

  /**
   * Whether the close button should be shown
   *
   * @default true
   */
  showClose?: Boolean
}

export const DialogHeader: React.FC<BaseProps> = (props) => (
  <Box.Header
    className={cn(dialogClasses.HEADER, props.className)}
    children={props.children}
  />
)

export const DialogBody: React.FC<BaseProps> = (props) => (
  <Box.Body
    className={cn(dialogClasses.BODY, props.className)}
    children={props.children}
  />
)

export const DialogFooter: React.FC<BaseProps> = (props) => (
  <Box.Footer
    className={cn(dialogClasses.FOOTER, props.className)}
    children={props.children}
  />
)

export type DialogComponent = React.FC<DialogProps> & {
  Header: typeof DialogHeader
  Body: typeof DialogBody
  Footer: typeof DialogFooter
  Wrap: typeof AttachedDialog
}

/**
 * Dialog overlay component
 */
export const Dialog: DialogComponent = (props) => {
  const {
    children,
    className,
    intent,
    position,
    showClose,
    size,
    ...overlayProps
  } = props

  const classNames = cn(
    dialogClasses.MAIN,
    {
      [`-${position}`]: position,
    },
    className,
  )

  const contentClassNames = cn(dialogClasses.CONTENT, {
    [`-${size}`]: size,
  })

  const motionVariants: Variants = {
    open: {
      y: 0,
      opacity: 1,
    },
    closed: {
      y: 16,
      opacity: 0,
    }
  }

  return (
    <Overlay {...overlayProps} transitionDuration={250} isBlocking>
      <div className={classNames}>
        <motion.div
          role="dialog"
          className={contentClassNames}
          variants={motionVariants}
          initial="closed"
          animate={props.isOpen ? 'open' : 'closed'}
          transition={{ duration: .350 }}
        >
          {showClose && (
            <Close
              appearance="minimal"
              intent={intent}
              onClick={overlayProps.onClose}
            />
          )}
          <Box isScrollable isFullHeight elevate={3} intent={intent}>
            {children}
          </Box>
        </motion.div>
      </div>
    </Overlay>
  )
}

Dialog.displayName = 'Dialog'

Dialog.defaultProps = {
  position: DialogPositionType.CENTER,
  size: DialogSizeType.AUTO,
  hasBackdrop: true,
  showClose: true,
  transitionDuration: 250,
  isScrollable: false,
}

Dialog.Header = DialogHeader
Dialog.Body = DialogBody
Dialog.Footer = DialogFooter
Dialog.Wrap = AttachedDialog
