import React, { useContext, ChangeEvent, FormEvent, MouseEvent } from 'react'

import { Choice, ChoiceProps } from '../../choice'
import { CheckboxGroupContext } from './checkbox-group'
import { safeInvoke } from '../../../../library'

//
// Checkbox
//

export interface CheckboxProps extends ChoiceProps { }

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const groupContext = useContext(CheckboxGroupContext)

  function handleUpdate(value: boolean, field: string) {
    if (groupContext) {
      safeInvoke(groupContext.onUpdate, field, value)
    }
    safeInvoke(props.onUpdate, value, field)
  }

  const { isChecked, onUpdate: onChange, ...groupProps } = groupContext

  const mergedProps = {
    ...groupProps,
    ...props,
    onUpdate: handleUpdate,
    isChecked: props.isChecked !== undefined ? props.isChecked : groupContext.isChecked(props.field)
  }

  return (
    <Choice type="checkbox" typeClassName={'checkbox'}  {...mergedProps} >
      <div className="indicator" />
    </Choice>
  )
}

Checkbox.displayName = 'Checkbox'
