import React, { MouseEventHandler, useState, useEffect, useLayoutEffect } from 'react'
import cn from 'classnames'

import { BaseProps } from '../../../types'

export const safe = (sum: number) => (sum < 0 ? 0 : sum)

export interface MaskProps extends BaseProps {
  padding?: number
  radius?: number
  disableInteraction?: boolean
  enableTargetInteraction?: boolean
  showMask?: boolean
  onClick?: MouseEventHandler
  target?: HTMLElement
}

interface Dimensions {
  top: number
  left: number
  width: number
  height: number
}

export const TourMask: React.FC<MaskProps> = (props) => {
  const [containerWidth, setContainerWidth] = useState(0)
  const [containerHeight, setContainerHeight] = useState(0)
  const [targetDimensions, setTargetDimensions] = useState<Dimensions>()

  useLayoutEffect(() => {
    setContainerDimensions()
    window.addEventListener('resize', setContainerDimensions)

    return () => {
      window.removeEventListener('resize', setContainerDimensions)
    }
  }, [props.target])

  function getPath(
    options: {
      hole?: Dimensions
      padding?: number
      radius?: number
    }
  ) {
    if (!options.hole) {
      return `M0,0 V${containerHeight} H${containerWidth} V0 Z`
    }

    const { padding = props.padding, radius = props.radius } = options

    const width = safe(options.hole.width + padding * 2)
    const height = safe(options.hole.height + padding * 2)
    const top = safe(options.hole.top - padding)
    const left = safe(options.hole.left - padding)

    return `
      M0,0 V${containerHeight} H${containerWidth} V0 Z
      M${left + radius},${top} h${width - radius * 2} a${radius},${radius} 0 0 1 ${radius},${radius} v${height - radius * 2} a${radius},${radius} 0 0 1 -${radius},${radius} h-${width - radius * 2} a${radius},${radius} 0 0 1 -${radius},-${radius} v-${height - radius * 2} a${radius},${radius} 0 0 1 ${radius},-${radius} Z
    `
  }

  function setContainerDimensions() {
    setContainerHeight(window.innerHeight)
    setContainerWidth(window.innerWidth)
    if (props.target) {
      const rect = props.target.getBoundingClientRect()
      setTargetDimensions({ top: rect.top, left: rect.left, width: rect.width, height: rect.height })
    } else {
      setTargetDimensions(undefined)
    }
  }

  return (
    <div className={cn('tour__mask', props.className)} onClick={props.onClick}>
      <svg
        width={containerWidth}
        height={containerHeight}
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* interaction blocker */}
        {props.disableInteraction &&
          <path
            d={getPath({
              hole: props.enableTargetInteraction ? targetDimensions : undefined,
              padding: 0,
              radius: 0
            })}
            pointerEvents="auto"
            fill="transparent"
          />
        }
        {/* visible mask */}
        {props.showMask && <path d={getPath({ hole: targetDimensions })} fill="currentcolor"></path>}
      </svg>
    </div>
  )
}

TourMask.defaultProps = {
  radius: 4,
  padding: 16,
  disableInteraction: true,
  showMask: true,
}
