import React from 'react'
import { find } from 'lodash-es'

import { SchemaStep } from '../../../../partials/resume/schema'

export interface SliderInputProps {
  options: SchemaStep['options']
  value: string
  onUpdate?: (values: string) => void
  id: string
}

export const SliderInput: React.FC<SliderInputProps> = (props) => {

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value
    if (typeof props.onUpdate === 'function') {
      props.onUpdate(value)
    }
  }

  const activeItem = find(props.options, ['value', props.value])
  const ActiveLabel = activeItem ? activeItem.label : ''

  return (
    <div className="slider-input">
      <h4 className="t--center c--gummy">{ActiveLabel}</h4>
      <div className="slider-input__bar">
        {props.options.map((option, index) => (
          <label className="slider-input__item" key={props.id + '_' + index}>
            <input
              id={props.id + '_' + index}
              type="radio"
              name={props.id}
              value={option.value}
              onChange={handleOnChange}
              checked={activeItem.value === option.value}
            />
            <div className="indicator"></div>
          </label>
        ))}
      </div>
    </div>
  )
}
