/** Modified from https://github.com/fkhadra/react-toastify/blob/master/src/utils/eventManager.js */

import { Enum } from '../../../library'

export const AlertActionType = Enum(
  'SHOW',
  'DISMISS',
  'CLEAR',
  'CONTAINER_MOUNT',
  'CONTAINER_UNMOUNT',
)
export type AlertActionType = Enum<typeof AlertActionType>

type ActionCallback = (params: Record<string, any>) => void

export const alertManager = {
  list: new Map<AlertActionType, ActionCallback[]>(),

  on(event: AlertActionType, callback: ActionCallback) {
    if (!this.list.has(event)) this.list.set(event, [])
    this.list.get(event).push(callback)
    return this
  },

  off(event: AlertActionType) {
    this.list.delete(event)
    return this
  },

  /**
   * Enqueue the event at the end of the call stack
   * Doing so let the user call toast as follow:
   * alert.show('1')
   * alert.show('2')
   * alert.show('3')
   * Without setTimemout the code above will not work
   */
  emit(event: AlertActionType, params: Record<string, any>) {
    if (this.list.has(event)) {
      this.list.get(event).forEach((callback) =>
        setTimeout(() => {
          callback(params)
        }, 0),
      )
    }
  },
}
