import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

import { BaseProps } from '../../../types'
import { isFalsy, safeInvoke } from '../../../library'

const portalClasses = {
  MAIN: 'portal'
}

export interface PortalProps extends BaseProps {
  /** Callback invoked when the children of this `Portal` have been added to the DOM. */
  onChildrenMount?: () => void

  /**
   * The HTML element that children will be mounted to.
   * @default document.body
   */
  container?: HTMLElement
}

/**
 * This component detaches its contents and re-attaches them to document.body.
 * Use it when you need to circumvent DOM z-stacking (for dialogs, popovers, etc.).
 * Any class names passed to this element will be propagated to the new container element on document.body.
 */
export const Portal: React.FC<PortalProps> = (props) => {
  const portalRef = useRef<HTMLDivElement>()
  const [hasMounted, setHasMounted] = useState(false)

  // Mounting / Unmounting of portal element
  useEffect(() => {
    if (isFalsy(props.container)) { return }

    portalRef.current = createContainerElement()
    props.container.appendChild(portalRef.current)
    setHasMounted(true)
    safeInvoke(props.onChildrenMount)

    return () => {
      if (portalRef.current != null) {
        portalRef.current.remove()
      }
    }
  }, [])

  // update className prop on portal DOM element
  useEffect(() => {
    if (portalRef.current != null) {
      portalRef.current.classList.add(portalClasses.MAIN)
      maybeAddClass(portalRef.current.classList, props.className)
    }
    return () => {
      if (portalRef.current != null) {
        portalRef.current.className = ''
      }
    }
  }, [props.className])

  //
  // Helpers
  //

  function createContainerElement(classes?: string): HTMLDivElement {
    const container = document.createElement('div')
    container.classList.add(portalClasses.MAIN)
    maybeAddClass(container.classList, classes)

    return container
  }

  //
  // Render
  //

  // Only render `children` once this component has mounted in a browser environment, so they are
  // immediately attached to the DOM tree and can do DOM things like measuring or `autoFocus`.
  // See long comment on componentDidMount in https://reactjs.org/docs/portals.html#event-bubbling-through-portals
  if (typeof document === 'undefined' || !hasMounted || isFalsy(portalRef.current)) {
    return null
  } else {
    return ReactDOM.createPortal(props.children, portalRef.current)
  }
}

Portal.defaultProps = {
  container: typeof document !== 'undefined' ? document.body : null
}

//
// Helpers
//

function maybeAddClass(classList: DOMTokenList, className?: string): void {
  if (className != null && className !== '') {
    classList.add(...className.trim().split(' '))
  }
}
