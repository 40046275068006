import React from 'react'
import cn from 'classnames'

import { BaseProps, Intent } from '../../../types'
import { getIntentClass } from '../../../library'

export interface CardProps extends BaseProps {
  intent?: Intent
  appearance?: 'fill' | 'outline'
}

type Card = React.FC<CardProps> & {
  Block: React.FC<BaseProps>
  Header: React.FC<BaseProps>
}

export const Card: Card = (props) => {
  const classNames = cn(
    'card',
    props.className,
    `-${props.appearance}`,
    getIntentClass(props.intent),
  )

  return (
    <div className={classNames}>
      {props.children}
    </div>
  )
}

Card.Block = (props) => {
  return (
    <div className={cn('card__block', props.className)}>
      {props.children}
    </div>
  )
}

Card.Header = (props) => {
  return (
    <div className={cn('card__Header', props.className)}>
      {props.children}
    </div>
  )
}

Card.defaultProps = {
  appearance: 'outline',
}
