import React from 'react';
import cn from 'classnames';
import { clamp } from 'lodash-es';

import { Enum, getIntentClass } from '../../../library/index';
import { BaseProps, IntentProps } from '../../../types';

//
// Constants
//

const boxClasses = {
  MAIN: 'box',
  BODY: 'box__body',
  HEADER: 'box__header',
  TITLE: 'box__title',
  FOOTER: 'box__footer',
}

//
// Box Header
//

export interface BoxHeaderProps extends BaseProps {
  /** Whether the header is dark, with an inverted title */
  isDark?: boolean

  /**
   * Displays a standardized heading. For more control you can use whatever
   * you'd like for the children.
   */
  title?: string
}

export const BoxHeader: React.FC<BoxHeaderProps> = props => {
  const className = cn(
    boxClasses.HEADER,
    { '-dark': props.isDark },
    props.className,
  )
  return (
    <div className={className}>
      {!props.title || props.children ? null : (
        <div className={boxClasses.TITLE}>{props.title}</div>
      )}
      {props.children}
    </div>
  )
}

BoxHeader.displayName = 'BoxHeader'
BoxHeader.defaultProps = {
  isDark: false,
}

//
// Box Body
//

export const BoxBody: React.FC<BaseProps> = props => {
  const className = cn(boxClasses.BODY, props.className)
  return <div className={className}>{props.children}</div>
}

BoxBody.displayName = 'BoxBody'

//
// Box Footer
//

export interface BoxFooterProps extends BaseProps { }

export const BoxFooter: React.FunctionComponent<BoxFooterProps> = props => {
  const className = cn(boxClasses.FOOTER, props.className)
  return <div className={className}>{props.children}</div>
}

BoxFooter.displayName = 'BoxFooter'

//
// Box
//

export const BoxAppearance = Enum({
  MINIMAL: 'minimal',
  OUTLINE: 'outline',
  FILL: 'fill',
})
export type BoxAppearance = Enum<typeof BoxAppearance>

export interface BoxProps extends BaseProps, IntentProps {
  /** The stylistic theme of the box */
  appearance?: BoxAppearance

  /** Whether the box will try to fill the height of it's parent container */
  isFullHeight?: boolean

  /** Whether the body of the box will scroll overflow content */
  isScrollable?: boolean

  /** The size of the box shadow */
  density?: 'compact' | 'relaxed'

  /** The size of the box shadow */
  elevate?: number
}

type BoxComponent = React.FC<BoxProps> & {
  Header: typeof BoxHeader
  Body: typeof BoxBody
  Footer: typeof BoxFooter
}

export const Box: BoxComponent = props => {
  const elevate = clamp(props.elevate, 4)
  const className = cn(
    boxClasses.MAIN,
    {
      [`-${props.density}`]: props.density,
      [`-${props.appearance}`]: props.appearance,
      [`elevate--${elevate}`]: elevate,
      '-scrollable': props.isScrollable,
      '-fullheight': props.isFullHeight,
    },
    getIntentClass(props.intent),
    props.className,
  )
  return <div className={className}>{props.children}</div>
}

Box.displayName = 'Box'
Box.defaultProps = {
  appearance: BoxAppearance.OUTLINE,
  elevate: 0,
}

Box.Header = BoxHeader
Box.Body = BoxBody
Box.Footer = BoxFooter
