import React from 'react'
import { findIndex, clamp } from 'lodash-es'

import { safeInvoke } from '../../../library'
import { Portal } from '../../portal'
import { TourMask } from './tour-mask'
import { TourStepOptions, TourStep } from './tour-step'


interface TourProps {
  steps: TourStepOptions[]
  showMask?: boolean
  disableInteraction?: boolean
  onClose?: () => void
}

interface TourState {
  currentStepIndex: number
  show: boolean
}


export class Tour extends React.Component<TourProps, TourState> {
  state = {
    currentStepIndex: -1,
    show: false,
  }

  static defaultProps = {
    showMask: false,
    disableInteraction: true,
    enableTargetInteraction: true
  }

  start = () => {
    this.setState({
      show: true,
    }, () => {
      this.goTo(0)
    })
  }

  goTo = (step: number | string) => {
    if (!this.state.show) return

    if (typeof step === 'string') {
      step = findIndex(this.props.steps, ['id', step])
      if (step === -1) return
    }
    const nextIndex = clamp(step, 0, this.props.steps.length - 1)
    const curStep = this.props.steps[this.state.currentStepIndex]
    const nextStep = this.props.steps[nextIndex]
    safeInvoke(curStep?.onClose)
    safeInvoke(nextStep?.beforeShow)
    this.setState({
      currentStepIndex: -1
    })

    window.setTimeout(() => {
      this.setState({
        currentStepIndex: nextIndex
      }, () => {
        safeInvoke(nextStep?.onShow)
      })
    }, nextStep.delay ? nextStep.delay : 450)
  }

  nextStep = () => {
    if (this.state.currentStepIndex === this.props.steps.length - 1) {
      this.close()
    } else {
      this.goTo(this.state.currentStepIndex + 1)
    }
  }

  prevStep = () => {
    this.goTo(this.state.currentStepIndex - 1)
  }

  close = () => {
    const currentStep = this.props.steps[this.state.currentStepIndex]
    if (currentStep) {
      safeInvoke(currentStep.onClose)
      safeInvoke(this.props.onClose)
    }
    this.setState({
      currentStepIndex: -1,
      show: false,
    })
  }

  private _renderBackdrop(step: TourStepOptions) {
    if (!step.showMask && !step.disableInteraction) return null

    const target = step.attachTo ? document.querySelector(step.attachTo) as HTMLElement : null

    return (
      <TourMask
        target={target}
        disableInteraction={step.disableInteraction}
        enableTargetInteraction={step.enableTargetInteraction}
        showMask={step.showMask}
      />
    )
  }

  render() {
    const currentStep: TourStepOptions = {
      showMask: this.props.showMask,
      disableInteraction: this.props.disableInteraction,
      ...this.props.steps[this.state.currentStepIndex]
    }

    return (
      <Portal className="tour">
        {this.state.show && this._renderBackdrop(currentStep)}
        {this.props.steps.map((step, index) => {
          return (
            <TourStep
              key={step.id}
              isOpen={index === this.state.currentStepIndex}
              next={this.nextStep}
              close={this.close}
              showMask={this.props.showMask}
              disableInteraction={this.props.disableInteraction}
              nextText={index === this.props.steps.length - 1 ? 'Finish' : 'Next'}
              {...step}
            />
          )
        })}
      </Portal>
    )
  }
}
