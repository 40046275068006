import { Enum } from './enum';

export const MaxWidthType = Enum({
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
  XXL: 'xxl',
});
export type MaxWidthType = Enum<typeof MaxWidthType>;

export const MaxWidthValues = {
  [MaxWidthType.XS]: 360,
  [MaxWidthType.SM]: 504,
  [MaxWidthType.MD]: 696,
  [MaxWidthType.LG]: 912,
  [MaxWidthType.XL]: 1152,
  [MaxWidthType.XXL]: 1440,
};

export function maxWidthClass(maxWidth?: MaxWidthType) {
  if (!maxWidth) return '';
  return `max--${maxWidth}`;
}
