import React from 'react'
import { find } from 'lodash-es'
import { PickByValue } from 'utility-types'
import cn from 'classnames'
import { IconName } from '@bleepblorb/portfolio-icons'

import {
  isFunction,
  safeInvoke,
  keys,
  getIntentClass,
} from '../../../../library'
import { BaseProps } from '../../../../types'
import { Icon } from '../../../icon'

//
// Definitions
//

/**
 * An object describing how to render a particular option.
 * An `optionRenderer` receives the option as its first argument, and this object as
 * its second argument.
 */
export interface IOptionRendererProps {
  // The unique identifier for the option
  id: string

  /** Click event handler to select this option. */
  handleClick: React.MouseEventHandler<HTMLElement>

  handleMouseEnter: React.MouseEventHandler<HTMLElement>

  index?: number

  /** Modifiers that describe how to render this option, such as `active` or `disabled`. */
  modifiers: {
    /** Whether this is an "active" (selected) option */
    active: boolean
    /**
     * Whether this is the focused option, meaning keyboard
     * interactions will act upon it.
     */
    focused: boolean

    /** Whether this option is disabled and should ignore interactions. */
    disabled: boolean
  }
}

/**
 * Type alias for a function that receives an item and props and renders a
 * JSX element (or `null`).
 */
export type OptionRenderer<T> = (
  option: T,
  itemProps: IOptionRendererProps,
) => JSX.Element | null

/**
 * An object describing how to render the list of options.
 * An `itemListRenderer` receives this object as its sole argument.
 */
export interface IOptionListRendererProps<T> {
  /**
   * The currently focused item (for keyboard interactions), or `null` to
   * indicate that no item is active.
   */
  // activeOptions: T | null

  /**
   * Array of all options in the list.
   * See `filteredOptions` for a filtered array based on `query` and predicate props.
   */
  options: T[]

  /**
   * A ref handler that should be attached to the parent HTML element of the menu options.
   * This is required for the active item to scroll into view automatically.
   */
  optionsParentRef: React.Ref<HTMLDivElement>

  /**
   * Call this function to render an item.
   * This retrieves the modifiers for the item and delegates actual rendering
   * to the owner component's `optionRenderer` prop.
   */
  renderOption: (item: T, index: number) => React.ReactNode | null

  /**
   * Keyboard handler for up/down arrow keys to shift the active item.
   * Attach this handler to any element that should support this interaction.
   */
  handleKeyDown: React.KeyboardEventHandler<HTMLElement>

  /**
   * Keyboard handler for enter key to select the active item.
   * Attach this handler to any element that should support this interaction.
   */
  handleKeyUp: React.KeyboardEventHandler<HTMLElement>
}

/** Type alias for a function that renders the list of options. */
export type OptionListRenderer<T> = (
  optionListProps: IOptionListRendererProps<T>,
) => React.ReactNode

export interface IOptionListProps<T = {}> extends BaseProps {
  /**
   * The currently focused item for keyboard interactions, or `null` to
   * indicate that no item is active. If omitted or `undefined`, this prop will be
   * uncontrolled (managed by the component's state). Use `onActiveItemChange`
   * to listen for updates.
   */
  activeOptions?: T[]

  /** Array of items in the list. */
  options: T[]

  /**
   * Determine if the given item is disabled. Provide a callback function, or
   * simply provide the name of a boolean property on the item that exposes
   * its disabled state.
   */
  optionDisabled?: keyof T | ((item: T) => boolean)

  // The Property that is a unique identifier for the option, or a function that
  // produces a unique identifier, given the option. This is used to determine equality
  optionIdProp: ((option: T) => string) | keyof PickByValue<T, string>

  // The Property that is a unique identifier for the option, or a function that
  // produces a unique identifier, given the option. This is used to determine equality
  optionSearchProp?: ((option: T) => string) | keyof PickByValue<T, string>

  /**
   * Custom renderer for an item in the dropdown list. Receives a boolean indicating whether
   * this item is active (selected by keyboard arrows) and an `onClick` event handler that
   * should be attached to the returned element.
   */
  renderOption: OptionRenderer<T>

  /**
   * Custom renderer for the contents of the dropdown.
   *
   * The default implementation invokes `itemRenderer` for each item that passes the predicate
   * and wraps them all in a `Menu` element. If the query is empty then `initialContent` is returned,
   * and if there are no items that match the predicate then `noResults` is returned.
   */
  renderOptionList?: OptionListRenderer<T>

  /**
   * React content to render when filtering items returns zero results.
   * If omitted, nothing will be rendered in this case.
   *
   * This prop is ignored if a custom `itemListRenderer` is supplied.
   */
  noResults?: JSX.Element

  /**
   * Invoked when user interaction should change the active item: arrow keys
   * move it up/down in the list, selecting an item makes it active, and
   * changing the query may reset it to the first item in the list if it no
   * longer matches the filter.
   *
   * If the "Create Item" option is displayed and currently active, then
   * `isCreateNewItem` will be `true` and `activeItem` will be `null`. In this
   * case, you should provide a valid `ICreateNewItem` object to the
   * `activeItem` _prop_ in order for the "Create Item" option to appear as
   * active.
   *
   * __Note:__ You can instantiate a `ICreateNewItem` object using the
   * `getCreateNewItem()` utility exported from this package.
   */
  onFocusedOptionChange?: (
    activeItem: T | null,
    isCreateNewItem: boolean,
  ) => void

  /**
   * Callback invoked when an item from the list is selected,
   * typically by clicking or pressing `enter` key.
   */
  onOptionSelect?: (item: T, event?: React.SyntheticEvent<HTMLElement>) => void

  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void

  onKeyUp?: (e: React.KeyboardEvent<HTMLElement>) => void
}

export interface IOptionListState<T> {
  /** The currently focused item (for keyboard interactions). */
  focusedOption: T | null
  query: string
}

//
// OptionList
//

export class OptionList<T> extends React.Component<
  IOptionListProps<T>,
  IOptionListState<T>
  > {
  static defaultProps: Partial<IOptionListProps> = {
    activeOptions: [],
  }

  _optionsParentRef = React.createRef<HTMLDivElement>()

  constructor(props: IOptionListProps<T>) {
    super(props)

    this.state = {
      focusedOption: null,
      query: '',
    }
  }

  _queryTimer: number

  checkEquality = (optionA: T | null, optionB: T | null) => {
    const { optionIdProp } = this.props
    if (optionA === null || optionB === null) {
      return optionA === optionB
    } else if (isFunction(optionIdProp)) {
      return optionIdProp(optionA) === optionIdProp(optionB)
    } else {
      return optionA[optionIdProp] === optionB[optionIdProp]
    }
  }

  //
  // Actions
  //

  setQuery = (value: string) => {
    window.clearTimeout(this._queryTimer)
    this.setState({ query: value })

    // find a potential match within the options
    const matches = this.props.options.filter(option => {
      if (!isFunction(this.props.optionSearchProp)) {
        const searchText = option[this.props.optionSearchProp]
        if (typeof searchText === 'string') {
        }
        return (
          typeof searchText === 'string' &&
          searchText.toLowerCase().substring(0, value.length) ===
          value.toLowerCase()
        )
      }
      return null
    })

    this._queryTimer = window.setTimeout(() => {
      this.setState({ query: '' })
    }, 200)

    // set that item to focused
    if (matches.length > 0) {
      return matches[0]
    }

    return null
  }

  getFocusedIndex = (options = this.props.options) => {
    const { focusedOption } = this.state
    // NOTE: this operation is O(n) so it should be avoided in render(). safe for events though.
    for (let i = 0; i < options.length; ++i) {
      if (this.checkEquality(options[i], focusedOption)) {
        return i
      }
    }
    return -1
  }

  getActiveIndex = (options = this.props.options) => {
    const { activeOptions } = this.props
    const activeOption = activeOptions[0]
    // NOTE: this operation is O(n) so it should be avoided in render(). safe for events though.
    for (let i = 0; i < options.length; ++i) {
      if (this.checkEquality(options[i], activeOption)) {
        return i
      }
    }
    return -1
  }

  getFocusedElement = () => {
    if (
      this._optionsParentRef == null ||
      this._optionsParentRef.current === null ||
      this._optionsParentRef.current.children === null
    )
      return null

    const focusedIndex = this.getFocusedIndex()
    return this._optionsParentRef.current.children.item(focusedIndex) as HTMLElement
  }

  getActiveElement() {
    if (!this.props.activeOptions || this.props.activeOptions.length === 0) return null
    if (
      this._optionsParentRef == null ||
      this._optionsParentRef.current === null ||
      this._optionsParentRef.current.children === null
    )
      return null

    const activeIndex = this.getActiveIndex()
    return this._optionsParentRef.current.children.item(activeIndex) as HTMLElement
  }

  getOptionElement(index: number): HTMLElement
  getOptionElement(option: T): HTMLElement
  getOptionElement(option: T | number): HTMLElement {
    let index = -1
    const { options } = this.props

    if (
      this._optionsParentRef == null ||
      this._optionsParentRef.current === null ||
      this._optionsParentRef.current.children === null
    )
      return null

    if (typeof option === 'number') {
      index = option
    } else {
      for (let i = 0; i < options.length; ++i) {
        if (this.checkEquality(options[i], option)) {
          index = i
        }
      }
    }

    return this._optionsParentRef.current.children.item(index) as HTMLElement
  }


  /**
   * Get the next enabled item, moving in the given direction from the start
   * index. A `null` return value means no suitable item was found.
   * @param items the list of items
   * @param isItemDisabled callback to determine if a given item is disabled
   * @param direction amount to move in each iteration, typically +/-1
   * @param startIndex which index to begin moving from
   */
  getFirstEnabledOption = (
    startIndex: number = 0,
    backwards: boolean = false,
  ): T | null => {
    const { options } = this.props
    const maxIndex = options.length - 1

    if (options.length === 0 || startIndex > maxIndex || startIndex < 0) {
      return null
    }
    // remember where we started to prevent an infinite loop
    let index = startIndex

    do {
      // find first non-disabled item
      if (!isOptionDisabled(options[index], this.props.optionDisabled)) {
        return options[index]
      }
      index = backwards ? index - 1 : index + 1
    } while (index !== startIndex && index <= maxIndex)
    return null
  }

  getItemsParentPadding = () => {
    // assert ref exists because it was checked before calling
    const { paddingTop, paddingBottom } = getComputedStyle(
      this._optionsParentRef.current!,
    )
    return {
      paddingBottom: pxToNumber(paddingBottom),
      paddingTop: pxToNumber(paddingTop),
    }
  }

  /**
   * Get the next enabled item, moving in the given direction from the start
   * index. A `null` return value means no suitable item was found.
   *
   * @param direction amount to move in each iteration, typically +/-1
   */
  getNextFocusOption(direction: number): T | null {
    const startIndex = this.getFocusedIndex()
    const backwards = direction < 0
    return this.getFirstEnabledOption(startIndex + direction, backwards)
  }

  setFocusOption(focusedOption: T | null, callback?: () => void) {
    this.setState({ focusedOption }, callback)
    safeInvoke(this.props.onFocusedOptionChange, focusedOption, false)
  }

  scrollFocusedItemIntoView = () => {
    const focusedElement = this.getFocusedElement()
    if (this._optionsParentRef === null || focusedElement === null) return

    const { offsetTop: activeTop, offsetHeight: activeHeight } = focusedElement
    const {
      offsetTop: parentOffsetTop,
      scrollTop: parentScrollTop,
      clientHeight: parentHeight,
    } = this._optionsParentRef.current
    // compute padding on parent element to ensure we always leave space
    const { paddingTop, paddingBottom } = this.getItemsParentPadding()

    // compute the two edges of the active item for comparison, including parent padding
    const activeBottomEdge =
      activeTop + activeHeight + paddingBottom - parentOffsetTop
    const activeTopEdge = activeTop - paddingTop - parentOffsetTop

    if (activeBottomEdge >= parentScrollTop + parentHeight) {
      // offscreen bottom: align bottom of item with bottom of viewport
      this._optionsParentRef.current.scrollTop =
        activeBottomEdge - parentHeight
    } else if (activeTopEdge <= parentScrollTop) {
      // offscreen top: align top of item with top of viewport
      this._optionsParentRef.current.scrollTop = activeTopEdge
    }
  }

  scrollFocusedItemToTop = () => {
    const focusedElement = this.getFocusedElement()
    if (this._optionsParentRef === null || focusedElement === null) return

    const { offsetTop: activeTop } = focusedElement
    const { offsetTop: parentOffsetTop } = this._optionsParentRef.current
    // compute padding on parent element to ensure we always leave space
    const { paddingTop } = this.getItemsParentPadding()

    // compute the two edges of the active item for comparison, including parent padding
    const activeTopEdge = activeTop - paddingTop - parentOffsetTop

    // offscreen top: align top of item with top of viewport
    this._optionsParentRef.current.scrollTop = activeTopEdge
  }

  isOptionActive = (option: T) => {
    return find(this.props.activeOptions, active =>
      this.checkEquality(active, option),
    ) !== undefined
      ? true
      : false
  }

  //
  // Handlers
  //

  handleOptionSelect = (option: T, e: React.SyntheticEvent<HTMLElement>) => {
    if (!isOptionDisabled(option, this.props.optionDisabled)) {
      safeInvoke(this.props.onOptionSelect, option, e)
    }
  }

  handleOptionMouseEnter = (option: T, e: React.MouseEvent<HTMLElement>) => {
    if (!isOptionDisabled(option, this.props.optionDisabled)) {
      this.setState({ focusedOption: option })
    } else {
      this.setState({ focusedOption: null })
    }
  }

  handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    const { keyCode, key } = event

    let nextActiveItem: T = null
    if (
      [
        keys.ALT,
        keys.ARROW_LEFT,
        keys.ARROW_RIGHT,
        keys.BACKSPACE,
        keys.CAPS_LOCK,
        keys.CONTROL,
        keys.DELETE,
        keys.ESCAPE,
        keys.META,
        keys.SHIFT,
        keys.TAB,
      ].indexOf(keyCode) >= 0
    )
      return

    if (event.isDefaultPrevented()) return

    event.preventDefault()
    event.stopPropagation()

    switch (keyCode) {
      case keys.ARROW_UP:
        nextActiveItem = this.getNextFocusOption(-1)
        break
      case keys.ARROW_DOWN:
        nextActiveItem = this.getNextFocusOption(1)
        break
      case keys.ENTER:
        this.handleOptionSelect(this.state.focusedOption, event)
        break
      default:
        nextActiveItem = this.setQuery(this.state.query + key)
        break
    }

    if (nextActiveItem != null) {
      this.setFocusOption(nextActiveItem, this.scrollFocusedItemIntoView)
    }
    safeInvoke(this.props.onKeyDown, event)
  }

  handleKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
    safeInvoke(this.props.onKeyUp, event)
  }

  //
  // Lifecycle
  //

  //
  // Render
  //

  defaultNoResults = () => {
    return (
      // <Pane>
      <div className="t--center t--sm">... No Options</div>
      // </Pane>
    )
  }

  /** default `OptionListRenderer` implementation */
  renderOptionList: OptionListRenderer<T> = listProps => {
    const { noResults, options } = this.props
    const items = options
      .map(listProps.renderOption)
      .filter(item => item != null)

    if (items.length === 0)
      return noResults ? noResults : this.defaultNoResults()

    return (
      <div
        className={cn('option-list', this.props.className)}
        ref={listProps.optionsParentRef}
        tabIndex={0}
        onKeyDown={listProps.handleKeyDown}
        onKeyUp={listProps.handleKeyUp}
      >
        {items}
      </div>
    )
  }

  /** wrapper around `itemRenderer` to inject props */
  renderOption = (option: T, index: number) => {
    const { focusedOption } = this.state
    const modifiers = {
      active: this.isOptionActive(option),
      focused: this.checkEquality(focusedOption, option),
      disabled: isOptionDisabled(option, this.props.optionDisabled),
    }
    const id = isFunction(this.props.optionIdProp)
      ? this.props.optionIdProp(option)
      : option[this.props.optionIdProp] + ''
    return this.props.renderOption(option, {
      index,
      id,
      modifiers,
      handleClick: e => this.handleOptionSelect(option, e),
      handleMouseEnter: e => this.handleOptionMouseEnter(option, e),
    })
  }

  render() {
    const { options, renderOptionList = this.renderOptionList } = this.props
    return renderOptionList({
      options,
      handleKeyDown: this.handleKeyDown,
      handleKeyUp: this.handleKeyUp,
      optionsParentRef: this._optionsParentRef,
      renderOption: this.renderOption,
    })
  }
}

// Helper Functions

function isOptionDisabled<T>(
  item: T | null,
  optionDisabled?: IOptionListProps<T>['optionDisabled'],
) {
  if (optionDisabled === undefined || optionDisabled == null || item == null) {
    return false
  } else if (isFunction(optionDisabled)) {
    return optionDisabled(item)
  }
  return !!item[optionDisabled]
}

function pxToNumber(value: string | null) {
  return value == null ? 0 : parseInt(value.slice(0, -2), 10)
}

//
// Option Item
//

interface IOptionItemProps extends BaseProps {
  isActive?: boolean
  isDisabled?: boolean
  isFocused?: boolean
  onClick?: React.EventHandler<React.MouseEvent>
  onMouseOver?: React.EventHandler<React.MouseEvent>
}

export const OptionItem: React.FC<IOptionItemProps> = props => {
  const classNames = cn(
    'option-item',
    {
      '-active': props.isActive,
      '-focused': props.isFocused,
      '-disabled': props.isDisabled,
    },
    getIntentClass(props.isActive ? 'primary' : 'default'),
  )

  const handleClick = (e: React.MouseEvent) => {
    safeInvoke(props.onClick, e)
  }

  const handleMouseOver = (e: React.MouseEvent) => {
    safeInvoke(props.onMouseOver, e)
  }

  return (
    <div
      className={classNames}
      onMouseUp={handleClick}
      onMouseOver={handleMouseOver}
    >
      <span className="option-item__text">{props.children}</span>
      <Icon name={IconName.Check} className="option-check" />
      <Icon name={IconName.Close} className="option-remove" />
    </div>
  )
}
