import { Data, Placement, Position as PopperPosition } from 'popper.js'

import { PopupPositionType } from './popup.types'
import { PositionType } from '../../../types'
import { isFalsy } from '../../../library'

//
// Placement Utils
//

/**
 * Converts a full placement to one of the four positions by stripping text after the `-`.
 */
export function getPosition(placement: Placement): PopperPosition {
  if (isFalsy(placement)) return 'top'
  return placement.split('-')[0] as PopperPosition
}

/**
 * Returns true if position is left or right.
 */
export function isVerticalPosition(side: PopperPosition): boolean {
  return ['left', 'right'].includes(side)
}

/**
 * Returns the opposite position.
 */
export function getOppositePosition(side: PopperPosition): PopperPosition {
  switch (side) {
    case 'top':
      return 'bottom'
    case 'left':
      return 'right'
    case 'bottom':
      return 'top'
    default:
      return 'left'
  }
}

/**
 * Returns the CSS alignment keyword corresponding to given placement.
 */
export function getAlignment(placement: Placement): string {
  const align = placement.split('-')[1] as 'start' | 'end' | undefined
  switch (align) {
    case 'start':
      return 'left'
    case 'end':
      return 'right'
    default:
      return 'center'
  }
}

/**
 * Convert a position to a popper placement.
 */
export function positionToPlacement(position: PopupPositionType): Placement {
  switch (position) {
    case PositionType.TOP_LEFT:
      return 'top-start'
    case PositionType.TOP:
      return 'top'
    case PositionType.TOP_RIGHT:
      return 'top-end'
    case PositionType.RIGHT_TOP:
      return 'right-start'
    case PositionType.RIGHT:
      return 'right'
    case PositionType.RIGHT_BOTTOM:
      return 'right-end'
    case PositionType.BOTTOM_RIGHT:
      return 'bottom-end'
    case PositionType.BOTTOM:
      return 'bottom'
    case PositionType.BOTTOM_LEFT:
      return 'bottom-start'
    case PositionType.LEFT_BOTTOM:
      return 'left-end'
    case PositionType.LEFT:
      return 'left'
    case PositionType.LEFT_TOP:
      return 'left-start'
    case 'auto':
    case 'auto-start':
    case 'auto-end':
      // Return the string unchanged.
      return position
    default:
      return assertNever(position)
  }
}

function assertNever(x: string): never {
  throw new Error('Unexpected position: ' + x)
}

//
// Popup modifiers
//

/**
 * Modifier helper function to compute popper transform-origin based on arrow position
 */
export function getTransformOrigin(data: Data): string {
  const position = getPosition(data.placement)
  if (data.arrowElement == null) {
    return isVerticalPosition(position)
      ? `${getOppositePosition(position)} ${getAlignment(position)}`
      : `${getAlignment(position)} ${getOppositePosition(position)}`
  } else {
    const arrowSizeShift = data.arrowElement.clientHeight / 2
    const { arrow } = data.offsets
    // can use keyword for dimension without the arrow, to ease computation burden.
    // move origin by half arrow's height to keep it centered.
    return isVerticalPosition(position)
      ? `${getOppositePosition(position)} ${arrow.top + arrowSizeShift}px`
      : `${arrow.left + arrowSizeShift}px ${getOppositePosition(position)}`
  }
}
