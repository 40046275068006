import { sprintf } from 'sprintf-js'
import { ResponsiveProp } from '../types'
import { Enum } from './enum'
import { isFalsy } from './validators'

/**
 *   The standard breakpoints.
 */
export const Breakpoint = Enum('xs', 'sm', 'md', 'lg', 'xl')
export type Breakpoint = Enum<typeof Breakpoint>;

export const BreakpointValues = {
  [Breakpoint.xs]: 0,
  [Breakpoint.sm]: 576,
  [Breakpoint.md]: 768,
  [Breakpoint.lg]: 992,
  [Breakpoint.xl]: 1200
}

/**
 * Get the CSS infix string for a given breakpoint
 *
 * @param breakpoint Breakpoint
 */
export const breakpointInfix = (breakpoint: Breakpoint): string => {
  if (isFalsy(breakpointPrev(breakpoint))) {
    // first breakpoint
    return ''
  } else {
    return `--@${breakpoint}`
  }
}

/**
 * Get the previous breakpoint
 *
 * @example
 *    breakpointPrev('lg')
 *      >> 'md'
 *
 * @param breakpoint Breakpoint
 * @return Breakpoint | null
 */
export const breakpointPrev = (breakpoint: Breakpoint): Breakpoint | null => {
  const breakpointNames = Object.keys(Breakpoint).map(e => Breakpoint[e])
  const index = breakpointNames.indexOf(breakpoint)
  return index > 0 ? breakpointNames[index - 1] : null
}

/**
 * Get the next breakpoint
 *
 * @example
 *    breakpointNext('lg')
 *      >> 'xl'
 *
 * @param breakpoint Breakpoint
 * @return Breakpoint | null
 */
export const breakpointNext = (breakpoint: Breakpoint): Breakpoint | null => {
  const breakpointNames = Object.keys(Breakpoint).map(e => Breakpoint[e])
  const index = breakpointNames.indexOf(breakpoint)
  return index < breakpointNames.length ? breakpointNames[index + 1] : null
}

/**
 * Generates an array of classes bases on props object. Template string is passed
 * the value from each key in the props object.
 *
 * @param template - sprintf template
 * @param props IResponsiveProp
 *
 */
export function responsiveClasses(
  template: string,
  props: ResponsiveProp<any>
): string[] {
  const classNames = []
  const keys = Object.keys(props)
  if (typeof props === 'object') {
    keys.forEach((key: Breakpoint) => {
      const cn = sprintf(template, props[key]).toLowerCase()
      classNames.push(`${cn}${breakpointInfix(key)}`)
    })
  } else {
    const cn = sprintf(template, props).toLowerCase()
    classNames.push(`${cn}${breakpointInfix(props)}`)
  }
  return classNames
}
