/*
 * Copyright 2018 Palantir Technologies, Inc. All rights reserved.
 *
 * Licensed under the terms of the LICENSE file distributed with this project.
 */

import React from 'react'
import ResizeObserver from 'resize-observer-polyfill'

import { findDOMNode } from 'react-dom'
import { safeInvoke } from '../../../library'

/** A parallel type to `ResizeObserverEntry` (from resize-observer-polyfill). */
export interface ResizeEntry {
  /** Measured dimensions of the target. */
  contentRect: DOMRectReadOnly

  /** The resized element. */
  target: Element
}

/** `ResizeSensor` requires a single DOM element child and will error otherwise. */
export interface ResizeSensorProps {
  /**
   * Callback invoked when the wrapped element resizes.
   *
   * The `entries` array contains an entry for each observed element. In the
   * default case (no `observeParents`), the array will contain only one
   * element: the single child of the `ResizeSensor`.
   *
   * Note that this method is called _asynchronously_ after a resize is
   * detected and typically it will be called no more than once per frame.
   */
  onResize: (entries: ResizeEntry[]) => void

  /**
   * If `true`, all parent DOM elements of the container will also be
   * observed for size changes. The array of entries passed to `onResize`
   * will now contain an entry for each parent element up to the root of the
   * document.
   *
   * Only enable this prop if a parent element resizes in a way that does
   * not also cause the child element to resize.
   * @default false
   */
  observeParents?: boolean
}

export class ResizeSensor extends React.PureComponent<ResizeSensorProps> {
  element: Element | null = null
  observer = new ResizeObserver(entries =>
    safeInvoke(this.props.onResize, entries),
  )

  //
  // Lifecycle
  //

  componentDidMount() {
    this.observeElement()
  }

  componentDidUpdate(prevProps: ResizeSensorProps) {
    this.observeElement(this.props.observeParents !== prevProps.observeParents)
  }

  componentWillUnmount() {
    this.observer.disconnect()
  }

  /**
   * Observe the DOM element, if defined and different from the currently
   * observed element. Pass `force` argument to skip element checks and always
   * re-observe.
   */
  observeElement = (force = false) => {
    const element = this.getElement()
    if (!(element instanceof Element)) {
      // stop everything if not defined
      this.observer.disconnect()
      return
    }

    if (element === this.element && !force) {
      // quit if given same element -- nothing to update (unless forced)
      return
    } else {
      // clear observer list if new element
      this.observer.disconnect()
      // remember element reference for next time
      this.element = element
    }

    // observer callback is invoked immediately when observing new elements
    this.observer.observe(element)

    if (this.props.observeParents) {
      let parent = element.parentElement
      while (parent != null) {
        this.observer.observe(parent)
        parent = parent.parentElement
      }
    }
  }

  getElement = () => {
    try {
      // using findDOMNode for two reasons:
      // 1. cloning to insert a ref is unwieldy and not performant.
      // 2. ensure that we resolve to an actual DOM node (instead of any JSX ref instance).
      return findDOMNode(this)
    } catch {
      // swallow error if findDOMNode is run on unmounted component.
      return null
    }
  }

  //
  // Render
  //

  public render() {
    // pass-through render of single child
    return React.Children.only(this.props.children)
  }
}
