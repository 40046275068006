import React from 'react'
import cn from 'classnames'

import { Popup, PopupProps, PopupTriggerType } from '../../popup'
import { IntentProps, Intent } from '../../../types'
import { getIntentClass } from '../../../library'

export interface PopoverProps extends PopupProps, IntentProps { }

export const popoverClasses = {
  MAIN: 'popover',
  BODY: 'popover__body'
}


/**
 * Popover
 */
export const Popover: React.FC<PopoverProps> = (props) => {
  const { intent, ...passProps } = props
  const classNames = cn(popoverClasses.MAIN, getIntentClass(intent))

  return (
    <Popup
      popupClassName={classNames}
      {...passProps}
    >
      {props.children}
    </Popup>
  )
}

Popover.defaultProps = {
  trigger: PopupTriggerType.CLICK,
  intent: Intent.NONE,
  isMinimal: false,
}
