import React from 'react'
import cn from 'classnames'

import { BaseProps } from '../../../types';
import {
  FlexAlign,
  FlexJustify,
  getAlignClasses,
  getJustifyClasses
} from '../../../library/flex';

export interface ButtonGroupProps extends BaseProps {
  isVertical?: boolean
  justify?: FlexJustify
  align?: FlexAlign
}

export const Buttons: React.FC<ButtonGroupProps> = (props) => {
  const classNames = cn(
    {
      'buttons': !props.isVertical,
    },
    getJustifyClasses(props.justify),
    getAlignClasses(props.align),
    props.className
  )

  return (
    <div className={classNames}>
      {props.children}
    </div>
  )
}
