import React from 'react'
import cn from 'classnames'
import { IconName } from '@bleepblorb/portfolio-icons'

import { Enum, getIntentClass, safeInvoke } from '../../../library'
import { SizeType, BaseProps, IntentProps } from '../../../types'
import { InputShape } from '../../form/input'
import { Icon } from '../../icon'

export const CloseAppearance = Enum({ FILL: 'fill', MINIMAL: 'minimal' })
export type CloseAppearance = Enum<typeof CloseAppearance>

export interface ICloseProps extends BaseProps, IntentProps {
  /** The appearance variant  */
  appearance?: CloseAppearance

  /** Does not accept any children */
  children?: never

  /** Triggered when the button is clicked */
  onClick?: (event?: React.SyntheticEvent<HTMLElement>) => void

  /** Triggered when the button registers a mousedown event */
  onMouseDown?: (event?: React.SyntheticEvent<HTMLElement>) => void

  /** The shape of the corners */
  shape?: InputShape

  /** The general size of the close button */
  size?: SizeType
}

export const Close: React.FC<ICloseProps> = props => {

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    safeInvoke(props.onClick, e)
  }

  function handleMouseDown(e: React.MouseEvent<HTMLButtonElement>) {
    safeInvoke(props.onMouseDown, e)
  }

  const classNames = cn(
    'close',
    {
      '-sm': props.size === SizeType.SMALL,
      '-lg': props.size === SizeType.LARGE,
    },
    `-${props.shape}`,
    `-${props.appearance}`,
    getIntentClass(props.intent),
    props.className,
  )
  return (
    <button
      type="button"
      className={classNames}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      aria-label="Close"
    >
      <Icon name={IconName.Close} />
    </button>
  )
}

Close.displayName = 'Close'

Close.defaultProps = {
  size: SizeType.MEDIUM,
  shape: InputShape.CIRCULAR,
  appearance: CloseAppearance.MINIMAL,
}
