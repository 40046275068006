import { Enum } from "./enum";

export const FlexJustify = Enum({
  START: 'start',
  END: 'end',
  CENTER: 'center',
  JUSTIFY: 'between',
  SPACE: 'around',
})
export type FlexJustify = Enum<typeof FlexJustify>

export const FlexAlign = Enum({
  TOP: 'start',
  BOTTOM: 'end',
  MIDDLE: 'center',
  BASELINE: 'baseline',
  STRETCH: 'stretch',
})
export type FlexAlign = Enum<typeof FlexAlign>

export function getJustifyClasses(align: FlexJustify) {
  if (align === undefined)
    return ''

  return `justify-content--${align}`
}

export function getAlignClasses(align: FlexAlign) {
  if (align === undefined)
    return ''

  return `align-items--${align}`
}
