
import { Enum, Breakpoint } from '../../../library'
import { BaseProps, ResponsiveProp } from '../../../types'

export type ColumnOrderType = number | 'first' | 'last'

export const ColumnAlignType = Enum({
  TOP: 'top',
  BOTTOM: 'bottom',
  MIDDLE: 'middle',
  BASELINE: 'baseline',
  STRETCH: 'stretch',
})
export type ColumnAlignType = Enum<typeof ColumnAlignType>

export const ColumnJustifyType = Enum({
  START: 'start',
  END: 'end',
  CENTER: 'center',
  JUSTIFY: 'justify',
  SPACE: 'space',
})
export type ColumnJustifyType = Enum<typeof ColumnJustifyType>

//
// Column
//

export interface ColumnProps extends BaseProps {
  /**
   * Self aligment. Utilizes flex alignment.
   */
  align?: ColumnAlignType

  /**
   * Cutom Ordering. Usefull for re-ordering columns different than DOM order,
   * primarly for responsive elements. Utilizes flexbox order.
   */
  order?: ResponsiveProp<ColumnOrderType>

  /**
   * Offests column {X} column-widths from the left.
   */
  offset?: ResponsiveProp<ColumnOrderType>

  /**
   * Sets the width of the column.
   */
  span?: ResponsiveProp<number | 'auto' | 'shrink'>
}

//
// Column Group
//

export interface ColumnGroupProps extends BaseProps {
  /**
   * Align the child columns. Derived from flex properties.
   */
  align?: ResponsiveProp<ColumnAlignType>

  /**
   * Collapse gutters so there is no spacing between columns.
   */
  collapse?: boolean

  /**
   * Justify the child columns. Derived from flex properties.
   */
  justify?: ResponsiveProp<ColumnJustifyType>

  /**
   * Set children colummns to spedified amount of column-widths; Max amount
   * based on css column count (default 12).
   */
  span?: ResponsiveProp<number | 'auto'>

  /**
   * Split children colummns into spedified amount. Only works for amount that
   * divides evently into total column count (default 12).
   */
  split?: ResponsiveProp<number>

  /**
   * Breakpoint at which to stack the columns into rows.
   * Applies to specifed breakpoint and below.
   */
  stack?: Breakpoint
}
