import React from 'react'
import cn from 'classnames'

import { ensureElement } from '../../../library'
import { Dialog, DialogProps } from './dialog'

export type AttachedDialogProps = Omit<
  DialogProps,
  'isOpen' | 'showClose'
> & {
  /**
   * When Set, the child target element is wrapped with a parent element.
   * This specifies what element you'd like to use.
   */
  targetTagName?: string

  /** The content that will be rendered into the dialog */
  content?: React.ReactChild
}

/**
 * Attached Dialog
 *
 * This is an uncontrolled Dialog that you can wrap around another element. The
 * Dialog will open when the child element is clicked. Similar to a popover.
 */
export const AttachedDialog: React.FC<AttachedDialogProps> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false)

  //
  // Handlers
  //

  const handleTargetClick: React.MouseEventHandler = e => {
    if (isOpen) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  //
  // Render
  //

  const renderTarget = () => {
    const { targetTagName, children } = props
    const [rawTarget, ...rest] = React.Children.toArray(children)
    const safeTarget = ensureElement(rawTarget)
    const targetClasses = cn({ '-dialog-open': isOpen })

    // # Force type to div, otherwise TS will error due to being too complex.
    const TagName = targetTagName as 'div'

    return (
      <React.Fragment>
        {TagName !== null ? (
          <TagName
            className={targetClasses}
            onClick={handleTargetClick}
            children={rawTarget}
          />
        ) : (
            React.cloneElement(safeTarget, {
              className: targetClasses,
              onClick: handleTargetClick,
            })
          )}
        {rest}
      </React.Fragment>
    )
  }

  const { targetTagName, onClose, children, ...dialogProps } = props

  return (
    <React.Fragment>
      {renderTarget()}
      <Dialog {...dialogProps} isOpen={isOpen} onClose={handleClose}>
        {props.content}
      </Dialog>
    </React.Fragment>
  )
}

AttachedDialog.displayName = 'AttachedDialog'

AttachedDialog.defaultProps = {
  targetTagName: null,
}
