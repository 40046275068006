import React from 'react'
import cn from 'classnames'

export interface TogglerProps extends React.HTMLAttributes<HTMLSpanElement> {
  options: string[]
  isActive?: boolean
  onUpdate?: (index: number) => void
  index?: number
}

export const Toggler: React.FunctionComponent<TogglerProps> = (props) => {
  const [activeIndex, setActiveIndex] = React.useState(0)
  const { options, isActive, className, onClick, onUpdate, index, ...htmlProps } = props

  function getIndex() {
    return props.index !== undefined ? props.index : activeIndex
  }

  function toggle(): void {
    if (!props.isActive) { return }
    let newIndex = getIndex()

    if (activeIndex == props.options.length - 1) {
      newIndex = 0
    } else {
      newIndex = newIndex + 1
    }

    setActiveIndex(newIndex)
    if (typeof onUpdate == 'function') {
      onUpdate(newIndex)
    }
  }


  return (
    <span onClick={toggle} className={cn("toggler", { '-active': isActive }, className)} {...htmlProps}>
      {options[getIndex()]}
    </span>
  )
}

Toggler.defaultProps = {
  isActive: true,
}
