import { RootBoundary, Options as PopperOptions } from '@popperjs/core'

import { Enum } from '../../../library'
import { PositionType, BaseProps } from '../../../types'
import { OverlayableProps, BackdropProps } from '../../overlay'
import { HTMLMotionProps, MotionProps } from 'framer-motion'

export const PopupPositionType = Enum({
  ...PositionType,
  AUTO: 'auto',
  AUTO_END: 'auto-end',
  AUTO_START: 'auto-start'
})
export type PopupPositionType = Enum<typeof PopupPositionType>

export interface PopoverEventProps {
  onClick?: React.MouseEventHandler
  onMouseOver?: React.MouseEventHandler
  onMouseLeave?: React.MouseEventHandler
  onFocus?: React.FocusEventHandler
  onBlur?: React.FocusEventHandler
}

export const PopupTriggerType = Enum({
  MOUSEDOWN: 'mousedown',
  CLICK: 'click',
  CLICK_TARGET: 'clickTarget',
  HOVER: 'hover',
  HOVER_TARGET: 'hoverTarget'
})
export type PopupTriggerType = Enum<typeof PopupTriggerType>

export interface PopupProps extends BaseProps, OverlayableProps {
  /** HTML props for the backdrop element. */
  backdropProps?: BackdropProps['backdropProps']

  /**
   *  Sets the boundary element used by Popper for its `flip` and
   * `preventOverflow` modifiers. Three shorthand keywords are supported;
   * Popper will find the correct DOM element itself.
   *
   * @default "scrollParent"
   */
  boundary?: RootBoundary

  /**
   * When enabled, `preventDefault()` is invoked on `click` events that close
   * this popover, which will prevent those clicks from closing outer
   * popovers. When disabled, clicking inside a `Classes.POPOVER_DISMISS`
   * element will close the parent popover.
   *
   *  @default false
   */
  captureDismiss?: boolean

  /** The content rendered within the popover. */
  content?: React.ReactNode

  /**
   * Whether the popover should default to open on render
   *
   * @default false
   */
  defaultIsOpen?: boolean

  /**
   * Enables an invisible overlay beneath the popover. This prevents interaction
   * with the rest of the docuemnt until the popover is closed.
   *
   * @default false
   */
  hasBackdrop?: boolean

  /**
   * The amount of time in milliseconds the popover remains open after the user
   * hovers off the trigger. The timer is cancelled if the user mouses over the
   * target before it expires.
   *
   * @default 150
   */
  hoverCloseDelay?: number

  /**
   * The amount of time in milliseconds the popover remains closed after the user
   * hovers over the trigger. The timer is cancelled if the user mouses leaves the
   * target before it expires.
   *
   * @default 0
   */
  hoverOpenDelay?: number

  /**
   * Prevents the popover from being shown if set to true.
   *
   * @default false
   */
  isDisabled?: boolean

  /**
   * Whether the popover is visible. Using this prop makes the popover a controlled
   * component and visibility will only be determined by this prop. if isDisabled
   * is set to true, this prop will be ignored and the popover will remain closed.
   */
  isOpen?: boolean

  /**
   * Whether the popover should open when focused via the keyboard.
   * Only applicable with 'hover' triggers.
   *
   * @default true
   */
  isOpenedOnFocus?: boolean

  /**
   * Minimal popver styling. Does not include pointer
   *
   * @default false
   */
  isMinimal?: boolean

  /**
   * Popper modifier options, passed directly to internal Popper instance. See
   * https://popper.js.org/popper-documentation.html#modifiers for complete
   * details.
   */
  modifiers?: PopperOptions['modifiers']

  /**
   * Animate the popper using motion
   */
  motionProps?: MotionProps

  /** Called when the target element is resized */
  onResize?: (popover: HTMLDivElement | null) => void

  /**
   * Called in controlled mode when the popover state *would* change
   * due to user interaction.
   */
  onVisibilityChange?: (
    nextOpenState: boolean,
    e?: React.SyntheticEvent<HTMLElement>,
  ) => void

  /**
   * Position of the popover.
   *
   * @default PopupPositionType.AUTO
   */
  position?: PopupPositionType

  /** Space-delimited string of class names applied to the popover */
  popupClassName?: string

  /** Function for obtaining the ref supplied to the popover element */
  popupRef?: (ref: HTMLDivElement | null) => void

  /** Space-delimited list of classes to be applied to the target element */
  targetClassName?: string

  /**
   * HTML props to spread to target element. Use targetTagName to change the
   * type of element rendered. Note that ref is not supported.
   */
  targetProps?: React.HTMLAttributes<HTMLElement>

  /** Function for obtaining the ref supplied to the target element */
  targetRef?: (ref: HTMLElement | null) => void

  /**
   * Wraps the target in the given tag. This must be an HTML element to ensure
   * that it supports the necessary DOM event handlers.
   */
  targetTagName?: keyof JSX.IntrinsicElements

  /**
   * The kind of interaction that triggers the popover.
   *
   * @default PopoverTriggerType.CLICK
   */
  trigger?: PopupTriggerType
}

export interface PopoverState {
  isOpen: boolean
  transformOrigin: string
}
