import React from 'react'
import cn from 'classnames'
import { icons, IconName } from '@bleepblorb/portfolio-icons'

import { BaseProps } from '../../../types'

export interface IconProps extends BaseProps {
  name: IconName
  height?: number
}

export const iconClassNames = {
  MAIN: 'icon',
}

export const Icon: React.FC<IconProps> = (props) => {
  const iconData = icons[props.name]
  return (
    <span className={cn(iconClassNames.MAIN, props.className)} style={{ height: props.height }}>
      <svg viewBox={`0 0 ${iconData.metadata.width} ${iconData.metadata.height}`} dangerouslySetInnerHTML={{ __html: iconData.source }} />
    </span >
  )
}

Icon.displayName = 'Icon'
