import React from 'react'
import { v4 as uuid } from 'uuid'
import { noop } from 'lodash-es'

import { AlertContainer } from './alert-container'
import { alertManager, AlertActionType } from './alert-manager'
import { DialogProps } from '../../dialog'
import { BaseProps, Intent, IntentProps } from '../../../types'

export interface AlertAction {
  /** Icon displayed within the button  */
  icon?: React.ReactNode

  /**
   * Whether the button is the primary action. Will be styled with the intent
   * matching the alert
   */
  isPrimary?: boolean

  /** Function to be executed when the button is clicked */
  onClick: any

  /** Text for the action button */
  label: string
}

export type AlertProps = BaseProps &
  Pick<
    DialogProps,
    'size' | 'position' | 'isClosedOnOutsideClick' | 'isClosedOnEscape'
  > &
  IntentProps &
{
  /**
   * List of possible user actions. Will be displayed as a button list within the dialog.
   */
  actions?: AlertAction[]

  /**
   * Content displayed within the dialog body.
   */
  body?: React.ReactChild

  /**
   * The label for the cancel button ( if cancellable ).
   */
  cancelText?: string

  /**
   * The label for the default convirmation button ( not used if actions are supplied ).
   */
  confirmText?: string

  /** Children not allowed */
  children?: never

  /**
   * A Unique id for the alert
   */
  id: string

  /**
   * Whether the dialog can be cancelled. This shows a cancel button and
   * sets the default for the isClosedOnEscape to true
   */
  isCancelable?: boolean

  /**
   * Function to be ran when the user cancels or closes the alert
   */
  onCancel?: () => void

  /**
   * Function is ran when the user resolves the alert (an action is clicked)
   */
  onResolve?: () => void

  /** Title to be displayed in the dialog header */
  title?: string
}

export type AlertOptions = Omit<AlertProps, 'id' | 'children'> & {
  id?: string
}

interface AlertDispatch {
  action: AlertActionType
  params: Record<string, any>
}

let container: AlertContainer = null
let queue: AlertDispatch[] = []

function dispatchAlert(props: AlertOptions) {
  const verifiedId = !!props.id ? props.id : `toast-${uuid()}`
  if (container !== null) {
    alertManager.emit(AlertActionType.SHOW, { props, id: verifiedId })
  } else {
    queue.push({
      params: { props, id: verifiedId },
      action: AlertActionType.SHOW,
    })
  }

  return verifiedId
}

export const alert = {
  /**
   * Show a Toast
   */
  show: (props: AlertOptions) => dispatchAlert(props),

  /**
   * Shortcuts for Toasts with a specific intent
   */
  primary: (props: AlertOptions) =>
    dispatchAlert({ ...props, intent: Intent.PRIMARY }),

  info: (props: AlertOptions) =>
    dispatchAlert({ ...props, intent: Intent.INFO }),

  success: (props: AlertOptions) =>
    dispatchAlert({ ...props, intent: Intent.SUCCESS }),

  warning: (props: AlertOptions) =>
    dispatchAlert({ ...props, intent: Intent.WARNING }),

  danger: (props: AlertOptions) =>
    dispatchAlert({ ...props, intent: Intent.DANGER }),

  /**
   * Remove alert
   */
  dismiss: (id: string) => {
    if (container !== null) {
      alertManager.emit(AlertActionType.DISMISS, { id })
    } else {
      queue.push({ params: { id }, action: AlertActionType.SHOW })
    }
  },

  /**
   * Do nothing until the container is mounted. Reassigned later
   */
  isActive: noop,
  getAlerts: noop,

  /**
   * Remove all toasts
   */
  clear: () => {
    if (container !== null) {
      alertManager.emit(AlertActionType.CLEAR, {})
    } else {
      queue.push({ params: {}, action: AlertActionType.CLEAR })
    }
  },
}

/**
 * Wait until the ToastContainer is mounted to dispatch the toast
 * and attach isActive method
 */
alertManager
  .on(AlertActionType.CONTAINER_MOUNT, ({ containerInstance }) => {
    container = containerInstance
    alert.isActive = id => container.isAlertActive(id)
    alert.getAlerts = () => container.getAlerts()

    queue.forEach(item => {
      alertManager.emit(item.action, item.params)
    })

    queue = []
  })
  .on(AlertActionType.CONTAINER_UNMOUNT, () => {
    container = null
    alert.isActive = noop
  })
