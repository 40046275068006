import React, { forwardRef, ReactNode, ButtonHTMLAttributes } from 'react'
import cn from 'classnames'

import { Intent, BaseProps, IntentProps } from '../../../types'
import { InputProps, InputSize, InputAppearance, InputShape } from '../../form/input'

export interface ButtonProps extends BaseProps, InputProps, IntentProps {
  isFullWidth?: boolean
  label?: ReactNode
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>>((props, ref) => {
  const { appearance, intent, shape, size, isFullWidth, className, label, children, ...htmlProps } = props

  const classList = cn(
    'button',
    `-${intent}`,
    `-${size}`,
    `-${shape}`,
    `-${appearance}`,
    {
      '-block': isFullWidth,
      '-disabled': htmlProps.disabled,
    },
    className
  )
  return (
    <button
      ref={ref}
      className={classList}
      {...htmlProps}
    >
      {label ? label : children}
    </button>
  )
})

Button.defaultProps = {
  appearance: InputAppearance.FILL,
  intent: Intent.NONE,
  size: InputSize.MEDIUM,
  shape: InputShape.ROUNDED,
}
