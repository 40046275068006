import React from 'react'
import Img, { FluidObject } from 'gatsby-image'
import { AnimatePresence, motion } from 'framer-motion'
import { wrap } from '@popmotion/popcorn'

import { SanityImageFluid } from '../../../types/server-types'
import { Container } from '../../container'
import { Icon } from '../../icon'
import { Columns, Column } from '../../column'

export interface ImageGalleryProps {
  images?: SanityImageFluid[]
  caption?: string
}

// Constants
const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? '100%' : '-100%',
    }
  },
  center: {
    zIndex: 1,
    x: 0,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? '100%' : '-100%',
    }
  },
}

const swipeConfidenceThreshold = 10000
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity
}

export const ImageGallery: React.FunctionComponent<ImageGalleryProps> = props => {
  const [[page, direction], setPage] = React.useState([0, 0])
  const imageIndex: number = wrap(0, props.images.length, page)
  const maxRatio = props.images.reduce((acc, cur) => {
    return (1 / cur.aspectRatio) > acc ? (1 / cur.aspectRatio) : acc
  }, 0)

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection])
  }

  const prev = () => {
    paginate(-1)
  }

  const next = () => {
    paginate(1)
  }

  return (
    <div className="gallery">
      <div className="gallery__content" style={{ paddingBottom: maxRatio * 100 + '%' }}>
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            className={'gallery__item'}
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 200, damping: 300 },
              opacity: { duration: 0.2 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x)

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1)
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1)
              }
            }}
          >
            <Img draggable={false} className={'gallery__image'} fluid={props.images[imageIndex] as FluidObject} />
          </motion.div>
        </AnimatePresence>
        <div className="gallery__control-next" onClick={next}>
          <div className="gallery__control-next-icon">
            <Icon name="ChevronRight" />
          </div>
        </div>
        <div className="gallery__control-prev" onClick={prev}>
          <div className="gallery__control-prev-icon">
            <Icon name="ChevronLeft" />
          </div>
        </div>
      </div>
      <Container maxWidth="lg" className="gallery__footer">
        <Columns>
          <Column className="gallery__caption" v-text="caption">
            {props.caption}
          </Column>
          <Column className="gallery__counter">
            {imageIndex + 1} / {props.images.length}
          </Column>
        </Columns>
      </Container>
    </div>
  )
}

ImageGallery.defaultProps = {
  images: [],
}
