import React from 'react'
import cn from 'classnames'

import { responsiveClasses } from '../../../library'
import { columnClasses } from './column'
import { ColumnGroupProps } from './column-types'


export const Columns: React.FC<ColumnGroupProps> = props => {
  const { align, children, collapse, justify, span, split, stack } = props
  let className = cn(
    columnClasses.GROUP,
    {
      '-collapse': collapse,
    },
    stack ? responsiveClasses('-stack', stack) : null,
    split ? responsiveClasses('-split-%s', split) : null,
    span ? responsiveClasses('-span-%s', span) : null,
    align ? responsiveClasses(`-%s`, align) : null,
    justify ? responsiveClasses('-%s', justify) : null,
    props.className,
  )

  return <div className={className} children={children} style={props.style} />
}

Columns.displayName = 'Columns'
Columns.defaultProps = {
  stack: null,
  split: null,
  collapse: false,
}
