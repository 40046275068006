import React from 'react'
import cn from 'classnames'

import { ButtonProps } from './button'
import { Intent } from '../../../types'
import { InputSize, InputShape, InputAppearance } from '../../form/input'

interface AnchorButtonProps extends ButtonProps {
  isDisabled?: boolean
}


export const AnchorButton = React.forwardRef<HTMLAnchorElement, AnchorButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>>((props, ref) => {
  const { appearance, intent, size, shape, isFullWidth, isDisabled, className, label, children, ...htmlProps } = props

  const classList = cn(
    'button',
    `-${appearance}`,
    `-${intent}`,
    `-${size}`,
    `-${shape}`,
    {
      '-block': isFullWidth,
      '-disabled': isDisabled
    },
    className
  )

  return (
    <a
      ref={ref}
      className={classList}
      {...htmlProps}
    >
      {label ? label : children}
    </a>
  )
})

AnchorButton.defaultProps = {
  appearance: InputAppearance.FILL,
  intent: Intent.NONE,
  size: InputSize.MEDIUM,
  shape: InputShape.ROUNDED,
}
