import React, { CSSProperties, RefObject } from 'react'

import { popupClasses } from './popup'

const SVG_SHADOW_PATH = `M2,19 L2,18 L34,18 L34,19 L32,19 C28.2805892,19 26.1327655,19.8254789 23.8078127,21.4946329 L20.6958734,24.0244724 C19.7652137,24.656814 18.7054196,24.9701335 17.6625,24.9701335 C16.6103912,24.9701335 15.5748358,24.6519392 14.6982344,24.0191265 L11.0083178,21.1069512 C8.9791096,19.7305899 6.764021,19 4,19 L2,19 Z`
const SVG_ARROW_PATH = `M2,18 L4,18 C7,18 9.4,18.8 11.6,20.3 L15.3,23.2201335 C16.7,24.2201335 18.6,24.2201335 20.1,23.2201335 L23.2,20.7 C25.7,18.9 28,18 32,18 L34,18 L34,17 L2,17 L2,18 Z`

export interface PopupArrowProps {
  style: CSSProperties
}

export const PopupArrow: React.FunctionComponent<PopupArrowProps> = (props) => (
  <div
    className={popupClasses.ARROW}
    style={props.style}
    data-popper-arrow
  >
    <svg viewBox="0 0 36 36">
      <path className={popupClasses.ARROW + '-border'} d={SVG_SHADOW_PATH} />
      <path className={popupClasses.ARROW + '-fill'} d={SVG_ARROW_PATH} />
    </svg>
  </div>
)
