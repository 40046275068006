import React, { RefObject, useLayoutEffect, useRef, useState, useMemo, CSSProperties } from 'react'
import {
  State,
  createPopper,
  Options as PopperOptions,
  VirtualElement,
  Instance as PopperInstance,
  Placement,
  ModifierArguments,
  Modifier,
} from '@popperjs/core'
import { isTruthy } from '../library/validators'


interface UsePositionerState {
  styles: {
    [key: string]: Partial<CSSStyleDeclaration>
  }
  attributes: {
    [key: string]: {
      [key: string]: string | boolean
    }
  }
  placement: Placement
}

export interface UsePositionerReturn {
  popper: {
    styles: Partial<CSSProperties>
    attributes: {
      [key: string]: string | boolean
    }
    placement: Placement;
  }
  arrow: {
    styles: Partial<CSSProperties>
    attributes: {
      [key: string]: string | boolean
    }
  }
  update?: PopperInstance['update']
  forceUpdate?: PopperInstance['forceUpdate']
}

export const usePositioner = (
  referenceRef: Element | VirtualElement,
  popperRef: HTMLElement,
  options: Partial<PopperOptions> = {}
): UsePositionerReturn => {
  const popperInstanceRef = useRef<PopperInstance>()
  const [state, setState] = useState<UsePositionerState>({
    styles: {
      popper: { position: 'absolute', left: '0', top: '0' },
    },
    attributes: {},
    placement: options.placement,
  })

  const getOptions = useMemo<Partial<PopperOptions>>(() => {
    return {
      ...options,
      modifiers: [
        ...options.modifiers,
        {
          name: 'updateState',
          enabled: true,
          phase: 'write',
          fn: updateStateModifier,
          options: { setState },
          requires: ['computeStyles']
        },
        { name: 'applyStyles', enabled: false }
      ]
    }
  }, [options])


  useLayoutEffect(() => {
    if (referenceRef && popperRef) {
      const popperInstance = createPopper(
        referenceRef,
        popperRef,
        getOptions
      )

      popperInstanceRef.current = popperInstance
    }

    return () => {
      if (isTruthy(popperInstanceRef.current)) {
        popperInstanceRef.current.destroy()
      }
    }
  }, [referenceRef, popperRef])

  useLayoutEffect(() => {
    if (isTruthy(popperInstanceRef.current)) {
      popperInstanceRef.current.setOptions(getOptions)
    }
  }, [options.placement, options.strategy])

  return {
    popper: {
      styles: (state.styles?.popper || {}) as CSSProperties,
      attributes: state.attributes?.popper || {},
      placement: state.placement,
    },
    arrow: {
      styles: (state.styles?.arrow || {}) as CSSProperties,
      attributes: state.attributes?.popper || {},
    },
    update: popperInstanceRef.current?.update,
    forceUpdate: popperInstanceRef.current?.forceUpdate
  }
}

type updateStateModifier = ModifierArguments<{ setState: (state: UsePositionerState) => void }>

function updateStateModifier({ state, options }: updateStateModifier): void {
  options.setState({
    styles: state.styles,
    attributes: state.attributes,
    placement: state.placement,
  })
}
