/**
 * Returns true if the given JSX element matches the given component type.
 *
 * NOTE: This function only checks equality of `displayName` for performance and
 * to tolerate multiple minor versions of a component being included in one
 * application bundle.
 * @param element JSX element in question
 * @param ComponentType desired component type of element
 */
export function isElementOfType<P = {}>(
  element: any,
  ComponentType: React.ComponentType<P>
): element is React.ReactElement<P> {
  return (
    element?.type?.displayName != null &&
    element.type.displayName === ComponentType.displayName
  )
}

/** Returns whether the value is a function. Acts as a type guard. */
export function isFunction(value: any): value is Function {
  return typeof value === 'function'
}

/**
 * Determines if an element is given element or contains given element
 * @param element HTMLElment
 * @param testElement HTMLElement
 *
 * @returns {boolean}
 */
export function elementIsOrContains(
  element: HTMLElement,
  testElement: HTMLElement
): boolean {
  if (isFalsy(element) || isFalsy(testElement)) return false
  return element === testElement || element.contains(testElement)
}

/**
 * Determine if the given value is truthy
 */
export function isTruthy(value: any): boolean {
  return (
    value !== false && value !== undefined && value !== null && value !== ''
  )
}

/**
 * Determine if the given value is truthy
 */
export function isFalsy(value: any): boolean {
  return (
    value === false || value === undefined || value === null || value === ''
  )
}

/**
 * Determine if the given value is a number or string representing an integer
 */
export function isNumber(value: any): value is number {
  return typeof value === 'number'
}

/**
 * Determine if the given value is an array
 */
export function isArray(value: any): value is any[] {
  return Array.isArray(value);
}

/**
 * Determine if the given value is a number or string representing an integer
 */
export function isObject(value: any): boolean {
  return value !== null && typeof value === 'object';
}

/**
 * Determine if the given value is an integer
 */
export function isInteger(value: any): boolean {
  return Number.isInteger(value)
}

/**
 * Returns whether `process.env.NODE_ENV` exists and equals `env`.
 */
export function isNodeEnv(env: string): boolean {
  return process?.env?.NODE_ENV === env
}
