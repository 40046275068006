import React, { useRef, useEffect, ChangeEventHandler } from 'react'
import { v4 as uuid } from 'uuid'
import cn from 'classnames'

import { BaseProps } from '../../../../types'
import { InputSize } from '../../input'
import { safeInvoke } from '../../../../library'

const choiceClasses = {
  switch: 'switch',
  checkbox: 'checkbox',
  radio: 'radio',
}

/**
 * Choice
 *
 * An internal component that is a shared base between checkbox, radio and switch comopnents
 */

export interface ChoiceProps extends BaseProps {
  /** Unique identifier. Defualts to a random string */
  field: string

  /** Whether the choice is checked. */
  isChecked?: boolean

  /** Whether the input is non-interactive */
  isDisabled?: boolean

  /** Text label for the choice */
  label?: React.ReactNode

  /** The Name applied to the input */
  name?: string

  /** A note to display under the choice. Similar to help text */
  note?: React.ReactChild

  /** Event handler invoked when input value is changed */
  onBlur?: React.FocusEventHandler<HTMLInputElement>

  /** Event handler invoked when input value is changed */
  onChange?: React.ChangeEventHandler<HTMLInputElement>

  /** Event handler invoked when input value is changed */
  onUpdate?: (value: boolean, field: ChoiceProps['field']) => void

  /** The size of the input. */
  size?: InputSize

  /** The value for the choice, applicable to radios */
  value?: React.ReactText
}

interface ChoiceInternalProps extends ChoiceProps {
  type: 'checkbox' | 'radio'
  typeClassName: string
}

export const Choice: React.FC<ChoiceInternalProps> = (props) => {
  const field = useRef(props.field)

  useEffect(() => {
    field.current = props.field ? props.field : 'input-' + uuid()
  }, [props.field])

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    safeInvoke(props.onChange, e)
    safeInvoke(props.onUpdate, e.currentTarget.checked, props.field)
  }



  const className = cn(
    props.typeClassName,
    {
      '-sm': props.size === InputSize.SMALL,
      '-lg': props.size === InputSize.LARGE,
      '-disabled': props.isDisabled,
    },
    props.className,
  )

  return (
    <div className={className}>
      <input
        id={field.current}
        type={props.type}
        onChange={handleChange}
        onBlur={props.onBlur}
        checked={props.isChecked}
        name={props.name}
        value={props.value}
        disabled={props.isDisabled}
      />
      <label htmlFor={field.current}>
        {props.children}
        {props.label}
      </label>
      {props.note && <div className="note">{props.note}</div>}
    </div>
  )
}

//
// Switch
//

export interface ISwitchProps extends ChoiceProps { }

export const Switch: React.FunctionComponent<ISwitchProps> = props => {
  return (
    <Choice {...props} type="checkbox" typeClassName={choiceClasses.switch}>
      <div className="indicator" />
    </Choice>
  )
}

Switch.displayName = 'Switch'

//
// Radio
//

export interface IRadioProps extends ChoiceProps { }

export const Radio: React.FunctionComponent<IRadioProps> = props => {
  return (
    <Choice {...props} type="radio" typeClassName={choiceClasses.radio} >
      <div className="indicator" />
    </Choice>
  )
}

Radio.displayName = 'Radio'
