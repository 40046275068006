import React, { useRef, useState, useEffect } from 'react'
import useInView from 'react-cool-inview'
import cn from 'classnames'

import { BaseProps } from '../../types'
import { AnimatePresence, motion } from 'framer-motion'
import { Icon } from '../../components/icon'


interface VideoProps extends BaseProps {
  sources: string[]
  isLooping?: boolean
  willAutoplay?: boolean
  isLazy?: boolean
}

export const ProjectVideo: React.FC<VideoProps> = (props) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [hasEnded, setHasEnded] = useState(false)

  useInView(videoRef, {
    threshold: 0,
    onEnter: handleEnter,
    onLeave: handleLeave,
  })

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('ended', handleEnded)
      return () => {
        videoRef.current.removeEventListener('ended', handleEnded)
      }
    }
  })

  function handleEnter() {
    if (!hasEnded) {
      videoRef.current.play()
    }
  }

  function handleLeave() {
    if (!videoRef.current) return
    videoRef.current.pause()
  }

  function handleEnded() {
    setHasEnded(true)
  }

  function handleRestart(e: React.MouseEvent) {
    e.preventDefault()
    if (!videoRef.current) return
    videoRef.current.currentTime = 0
    videoRef.current.play()
    setHasEnded(false)
  }

  return (
    <div className={cn('project-video', props.className)}>
      <video
        ref={videoRef}
        loop={props.isLooping}
        autoPlay={props.willAutoplay}
        style={props.style}
        muted
      >
        {props.sources.map(((source, index) => {
          const type = source.split('.').slice(-1)
          return <source key={index} src={source} type={`video/${type}`} />
        }))}
      </video>
      <AnimatePresence>
        {hasEnded &&
          <div className="restart">
            <motion.a
              href="#"
              onClick={handleRestart}
              initial={{ opacity: 0, scale: .9, translateY: 8 }}
              animate={{ opacity: 1, scale: 1, translateY: 0 }}
              exit={{ opacity: 0, scale: .9 }}
              whileHover={{ scale: 1.15 }}
            >
              <Icon name="Refresh" />
            </motion.a>
          </div>
        }
      </AnimatePresence>
    </div>
  )
}