import React from 'react'
import cn from 'classnames'

import { responsiveClasses } from '../../../library'
import { ColumnProps } from './column-types'

//
// Constants
//

export const columnClasses = {
  COlUMN: 'column',
  GROUP: 'columns',
}

/**
 * A Column
 */
export const Column: React.FC<ColumnProps> = props => {
  const { align, children, offset, order, span } = props
  let className = cn(
    columnClasses.COlUMN,
    align ? responsiveClasses('-%s', align) : null,
    offset ? responsiveClasses('-offset-%s', offset) : null,
    order ? responsiveClasses('-order-%s', order) : null,
    span ? responsiveClasses('-span-%s', span) : null,
    props.className,
  )

  return <div className={className} children={children} style={props.style} />
}

Column.displayName = 'Column'
Column.defaultProps = {}
