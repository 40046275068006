import React from 'react'
import cn from 'classnames'

import { Popup, PopupProps, PopupTriggerType } from '../../popup'
import { IntentProps, Intent } from '../../../types'
import { getIntentClass } from '../../../library'

export interface TooltipProps extends PopupProps, IntentProps { }

export const tooltipClasses = {
  MAIN: 'tooltip',
  BODY: 'tooltip__body'
}


/**
 * Popover
 */
export const Tooltip: React.FC<TooltipProps> = (props) => {
  const { intent, ...passProps } = props
  const classNames = cn(tooltipClasses.MAIN, getIntentClass(intent))

  return (
    <Popup
      popupClassName={classNames}
      {...passProps}
    />
  )
}

Tooltip.defaultProps = {
  trigger: PopupTriggerType.HOVER_TARGET,
  intent: Intent.NONE
}
