import React from 'react'
import { keyBy } from 'lodash'

import { SanityResponsiveImage, SanityResponsiveImageOverride } from '../types/server-types'
import { Breakpoint, BreakpointValues, breakpointNext } from '../library/breakpoints'
import { BaseProps } from '../types'

export interface ResponsiveImageProps extends BaseProps {
  image: SanityResponsiveImage;
  chilcren?: never;
}

export const ResponsiveImage: React.FC<ResponsiveImageProps> = (props) => {

  function getNextOverride(overrides: SanityResponsiveImageOverride[], bp: Breakpoint = Breakpoint.xs): SanityResponsiveImageOverride | null {
    const keyedOverrides = keyBy(overrides, 'width');
    let nextBp = breakpointNext(bp);

    if (!nextBp) return null;

    if (!!keyedOverrides[nextBp]) {
      return keyedOverrides[nextBp]
    } else {
      return getNextOverride(overrides, nextBp);
    }
  }

  const baseAsset = props.image.xs.asset.fluid;
  const overrides = props.image.overrides;
  const firstOverride = getNextOverride(overrides);

  return (
    <picture className={props.className} style={props.style}>
      <source
        srcSet={baseAsset.srcSet}
        sizes={baseAsset.sizes}
        media={
          firstOverride
            ? `(max-width: ${BreakpointValues[firstOverride.width as Breakpoint]}px)`
            : undefined
        }
      />
      {overrides.map((override) => {
        const min = BreakpointValues[override.width as Breakpoint];
        const nextOverride = getNextOverride(overrides, override.width as Breakpoint)
        const max = nextOverride ? BreakpointValues[nextOverride.width as Breakpoint] : null;
        return (
          <source
            srcSet={override.image.asset.fluid.srcSet}
            sizes={baseAsset.sizes}
            media={`(min-width: ${min}px) ${max ? `and (max-width: ${max}px)` : ''}`}
          />
        )
      })}
      <img
        srcSet={baseAsset.srcSet}
        sizes={baseAsset.sizes}
        src={baseAsset.src}
        className={'-fluid'}
      />
    </picture>
  )
}
