import React from 'react'
import Layout from './layout'
import Img, { FluidObject } from 'gatsby-image'
import Block from '@sanity/block-content-to-react'
import cn from 'classnames'
import { getYear } from 'date-fns'
import { ReplaceComponentRendererArgs, Link } from 'gatsby'

import {
  SanityProject,
  SanityImageFillOrImageGalleryOrImageGroupOrProjectVideoOrTextBlock,
  SanityImageGroup,
  SanityProjectVideo,
  SanityTextBlock,
  SanityImageFill,
  SanityImageGallery
} from '../types/server-types'
import { MaxWidthType } from '../library'
import { marginClass, paddingClass } from '../library/spacing'
import {
  Container,
  Columns,
  Column,
  Heading,
  ImageGallery,
  Icon,
  FadeIn,
} from '../components'
import { ProjectVideo, SEO } from '../partials'

interface ProjectPageProps extends ReplaceComponentRendererArgs {
  pageContext: {
    project: SanityProject
    nextProject: SanityProject
  }
}

const ProjectPage: React.FunctionComponent<ProjectPageProps> = (props) => {
  const { pageContext: { project, nextProject } } = props

  function renderImageGallery(gallery: SanityImageGallery) {
    return (
      <section
        className={cn(
          gallery.backgroundColor ? paddingClass([12, null]) : marginClass([12, null]),
        )}
        style={{ backgroundColor: gallery.backgroundColor }}
        key={gallery._key}
      >
        <div className={cn(`max--${gallery.width}`, marginClass([null, 'auto']))} >
          <div className="g__row">
            <ImageGallery
              caption={gallery.caption}
              images={gallery.images.map(a => a.asset.fluid)}
            />
          </div>
        </div>
      </section>
    )
  }

  function renderImageGroup(imageGroup: SanityImageGroup) {
    return (
      <section
        key={imageGroup._key}
        className={cn(imageGroup.backgroundColor ? paddingClass([12, null]) : marginClass([12, null]))}
        style={{ backgroundColor: imageGroup.backgroundColor }}
      >
        <Container maxWidth={imageGroup.maxWidth ? imageGroup.maxWidth as MaxWidthType : 'xl'}>
          <Columns align="middle">
            {imageGroup.columns && imageGroup.columns.map((col, i) => {
              return (
                <Column
                  span={{ xs: 12, md: 12 / imageGroup.columns.length }}
                  className={cn({ [marginClass([null, null, 6])]: i + 1 < imageGroup.columns.length })}
                >
                  {col.images.map((img, index) => {
                    return (
                      <Img
                        fluid={img.asset.fluid as FluidObject}
                        className={cn({ [marginClass([null, null, 6])]: index + 1 < col.images.length })}
                      />
                    )
                  })}
                </Column>
              )
            })}
          </Columns>
        </Container>
      </section>
    )
  }

  function renderImageFill(imageFill: SanityImageFill) {
    return (
      <section key={imageFill._key}>
        <Img fluid={imageFill.image.asset.fluid as FluidObject} />
        {imageFill.caption &&
          <Container className={marginClass([4, null, null])}>
            <Columns align="middle">
              <Column className={cn(marginClass([null, null, 4]))}>
                <p className="c--gray-light">
                  {imageFill.caption}
                </p>
              </Column>
            </Columns>
          </Container>
        }
      </section>
    )
  }

  function renderTextBlock(textBlock: SanityTextBlock) {
    return (
      <section
        key={textBlock._key}
        className={cn(
          textBlock.backgroundColor ? paddingClass([12, null]) : marginClass([12, null]),
          {
            [`t--${textBlock.align}`]: textBlock.align,
          }
        )}
        style={{ backgroundColor: textBlock.backgroundColor }}
      >
        <Container maxWidth={textBlock.width as MaxWidthType}>
          <Columns>
            <Column className="text-block">
              <Block blocks={textBlock._rawBody}></Block>
            </Column>
          </Columns>
        </Container>
      </section>
    )
  }

  function renderVideo(video: SanityProjectVideo) {
    return (
      <section
        key={video._key}
        className={cn(
          video.backgroundColor ? paddingClass([12, null]) : marginClass([12, null]),
        )}
        style={{ backgroundColor: video.backgroundColor }}
      >
        <Container maxWidth={video.maxWidth as MaxWidthType}>
          <Columns>
            <Column className="text-block">
              <ProjectVideo
                sources={video.urls}
                isLooping={video.loop}
                isLazy={true}
                className={"-rounded -floating"}
              />
            </Column>
          </Columns>
          {video.caption &&
            <Columns>
              <Column>
                <p className={cn(paddingClass([4, 6]))}>
                  {video.caption}
                </p>
              </Column>
            </Columns>
          }
        </Container>
      </section>
    )
  }

  function renderBody(body: SanityImageFillOrImageGalleryOrImageGroupOrProjectVideoOrTextBlock[]) {
    return body.map((block, index) => {
      switch (block.__typename) {
        case 'SanityImageGallery':
          return renderImageGallery(block)
        case 'SanityImageGroup':
          return renderImageGroup(block)
        case 'SanityTextBlock':
          return renderTextBlock(block)
        case 'SanityImageFill':
          return renderImageFill(block)
        case 'SanityProjectVideo':
          return renderVideo(block)
        default:
          return null
      }
    })
  }

  function yearRange(start: string, end: string) {

    const startYear = getYear(new Date(start))
    const endYear = getYear(new Date(end))

    if (!start) return endYear

    if (startYear === endYear)
      return endYear

    return `${startYear} – ${endYear}`
  }


  return (
    <Layout>
      <SEO title={'Projects | ' + project.title} />

      <div className="project menu-pad">
        <Container maxWidth="xl" className={cn("project__intro", marginClass([null, null, 12]))}>
          <Columns className="max--md">
            <Column>
              <FadeIn tagName="div">
                <Heading size={6} margin={[null, null, 6]}>{project.title}—</Heading>
                <p className="">
                  {project.intro}
                </p>
              </FadeIn>
            </Column>
          </Columns>
        </Container>

        <section className={cn('project__body')}>
          {project.coverPhoto &&
            <Img
              fluid={project.coverPhoto.asset.fluid as FluidObject}
              className={cn("-fill", marginClass([null, null, 12]))}
            />
          }
          {renderBody(project.body)}
        </section>

        <section className={cn('project__credits', marginClass([12, null]))}>
          <Container maxWidth="xl">
            <Columns className={cn('max--lg')}>
              <Column span={{ xs: 12, md: 4 }} className={cn(marginClass([null, null, 5]), 't--mono', 'c--gray-light')}>
                <Heading size={2} family="sans" margin={[null, null, 2]}>Firm</Heading>
                <ul>
                  <li>{project.firm}</li>
                </ul>
                <Heading size={2} family="sans" margin={[6, null, 2]}>Year</Heading>
                <ul>
                  <li>{yearRange(project.startDate, project.finishDate)}</li>
                </ul>
              </Column>
              <Column span={{ xs: 12, md: 8 }}>
                <Columns>
                  {project.projectMeta && project.projectMeta.map((meta, index) => (
                    <Column key={meta._key} span={12} className={cn(marginClass([null, null, 6]), 't--mono', 'c--gray-light')}>
                      <Heading size={2} family="sans" margin={[null, null, 2]}>{meta.heading}</Heading>
                      <Block blocks={meta._rawList} />
                    </Column>
                  ))}
                </Columns>
              </Column>
            </Columns>
          </Container>
        </section>

        <section className={cn('project__footer', 'bg--jazzy', paddingClass([12, null]))}>
          <Container>
            {project.isFeatured ?
              <Columns align="middle">
                <Column span={3} >
                  <Link to="/projects" className="back-btn">
                    <Icon name="ArrowLeftLg" height={36} className="c--white -xl" />
                  </Link>
                </Column>
                <Column span={{ md: 6 }}>
                  <Heading size={2} family="mono" className="c--white">Enjoy Next —</Heading>
                  <Link to={`/projects/${nextProject.slug.current}`} className="heading f--6 c--white">
                    {nextProject.title}
                  </Link>
                </Column>
              </Columns>
              :
              <Columns align="middle">
                <Column span={3} >
                  <Link to="/projects" className="back-btn">
                    <Icon name="ArrowLeftLg" height={36} className="c--white -xl" />
                  </Link>
                </Column>
                <Column span={{ md: 6 }}>
                  <Link to={`/projects`} className="heading f--6 c--white">
                    Back To Projects
                  </Link>
                </Column>
              </Columns>
            }
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default ProjectPage
