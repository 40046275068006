import { Enum } from '../library'

/**
 * The four basic intents.
 */
export const Intent = Enum({
  NONE: 'default',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
});

export type Intent = Enum<typeof Intent>;
