
import { Enum } from '../library/enum'

export const PositionBaseType = Enum({
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left'
})
export type PositionBaseType = Enum<typeof PositionBaseType>;

export const PositionType = Enum({
  ...PositionBaseType,
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  LEFT_TOP: 'left-top',
  LEFT_BOTTOM: 'left-bottom',
  RIGHT_TOP: 'right-top',
  RIGHT_BOTTOM: 'right-bottom',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right'
})
export type PositionType = Enum<typeof PositionType>;

export function isPositionHorizontal (position: PositionType): boolean {
  return (
    position === PositionType.TOP ||
    position === PositionType.TOP_LEFT ||
    position === PositionType.TOP_RIGHT ||
    position === PositionType.BOTTOM ||
    position === PositionType.BOTTOM_LEFT ||
    position === PositionType.BOTTOM_RIGHT
  )
}

export function isPositionVertical (position: PositionType): boolean {
  return (
    position === PositionType.LEFT ||
    position === PositionType.LEFT_TOP ||
    position === PositionType.LEFT_BOTTOM ||
    position === PositionType.RIGHT ||
    position === PositionType.RIGHT_TOP ||
    position === PositionType.RIGHT_BOTTOM
  )
}
