import React, { useState, useEffect, ReactNode } from 'react'
import cn from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'

import { usePositioner } from '../../../hooks/use-positioner'
import { PopupPositionType } from "../../popup"
import { positionToPlacement, getPosition } from '../../popup/src/popup-utils'
import { PopupArrow } from '../../popup/src/popup-arrow'
import { Button } from '../../button'
import { Close } from '../../close'
import { isPositionVertical, isPositionHorizontal } from '../../../types'

export interface TourStepOptions {
  attachTo?: string
  disableInteraction?: boolean
  enableTargetInteraction?: boolean
  id: string
  showMask?: boolean
  showNavigation?: boolean
  showClose?: boolean
  heading?: ReactNode
  text?: ReactNode
  position?: PopupPositionType
  nextText?: string
  beforeShow?: () => void
  onShow?: () => void
  onClose?: () => void
  delay?: number
}


export type TourStepProps = TourStepOptions & {
  isOpen: boolean
  close: () => void
  next: () => void
}

export const TourStep: React.FC<TourStepProps> = (props) => {
  const [referenceElementRef, setReferenceElementRef] = useState<Element>(null)
  const [popupElementRef, setPopupElementRef] = useState<HTMLElement>(null)
  const { popper, arrow, update, forceUpdate } = usePositioner(referenceElementRef, popupElementRef, {
    placement: props.position ? positionToPlacement(props.position) : 'top',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'preventOverflow', options: { padding: 8 } }
    ],
  })

  useEffect(() => {
    if (props.attachTo) {
      setReferenceElementRef(document.querySelector(props.attachTo))
    }
  }, [props.attachTo, props.isOpen])

  function renderContent() {
    const placement = positionToPlacement(props.position)
    const position = getPosition(placement)
    const x = isPositionVertical(position)
      ? position === 'left'
        ? -8
        : 8
      : 0
    const y = isPositionHorizontal(position)
      ? position === 'top'
        ? -8
        : 8
      : 0

    const motionProps = {
      initial: { x, y, opacity: 0 },
      animate: { x: 0, y: 0, opacity: 1 },
      exit: { opacity: 0 },
      transition: { duration: .4 },
    }
    return (
      <motion.div className="tour-step__content" {...motionProps}>
        {props.showClose && <Close onClick={props.close} />}
        {props.heading && <div className={cn('tour-step__heading')}>{props.heading}</div>}
        {props.text && <p className="tour-step__text">{props.text}</p>}
        {props.showNavigation &&
          <div className="tour-step__nav">
            <Button label={props.nextText} size="small" shape="circular" onClick={props.next} className="-reverse" />
          </div>
        }
        <PopupArrow style={arrow.styles} />
      </motion.div>
    )
  }

  return (
    <AnimatePresence>
      {props.isOpen && referenceElementRef &&
        <div
          ref={setPopupElementRef}
          {...popper.attributes}
          style={popper.styles}
          className="tour-step"
        >
          {renderContent()}
        </div>
      }
      {props.isOpen && !referenceElementRef &&
        <div className="dialog -center">
          <div className="tour-step">
            {renderContent()}
          </div>
        </div>
      }
    </AnimatePresence>
  )
}

TourStep.defaultProps = {
  showClose: true,
  showNavigation: true,
  nextText: 'Next',
  position: 'top',
}
