import React from 'react'
import cn from 'classnames'
import { xorBy } from 'lodash-es'
import { PickByValue } from 'utility-types'

import {
  isFunction,
  safeInvoke,
  getIntentClass,
  keys,
} from '../../../../library'
import { PopupProps, PopupTriggerType, Popup } from '../../../popup'
import { Close } from '../../../close'
import {
  OptionList,
  IOptionListProps,
  OptionRenderer,
  OptionItem,
} from './option-list'
import { IntentProps, BaseProps } from '../../../../types'
import { InputProps } from '../../input'
import { getInputClasses } from '../../input/src/input-helpers'
import { Icon } from '../../../icon'

//
// Constants
//

export const selectClassNames = {
  MAIN: 'select',
  INPUT: 'select__input',
  OPTIONS: 'select__options',
  LABEL: 'input__text',
  PLACEHOLDER: '-placeholder',
  ACTION: 'select__action',
  ADDON: 'input__addon',
}

//
// Definitions
//

export interface ISelectProps<T>
  extends BaseProps,
  IntentProps,
  InputProps,
  Omit<IOptionListProps<T>, 'renderOption'>,
  Pick<PopupProps, 'boundary'> {
  /**
   * Whether muliple options can be selected.
   * @default false
   */
  allowMultiple?: boolean

  /** Content shown to the left of the select. Good for icons, etc. */
  before?: React.ReactNode

  /**
   * Whether the popover should close when an item is set.
   * Defaults to true for standard, false when isMultiple is set.
   */
  closeOnSelect?: boolean

  /**
   * A unique Identifier for the input
   */
  field: string

  /**
   * Whether you can clear the select after an item has been selected
   * @default false
   */
  isClearable?: boolean

  /**
   * Whether the select is non-interactive
   * @default false
   */
  isDisabled?: boolean

  /**
   * Whether the select is required. This is for styling only, NOT validation
   * @default false
   */
  isRequired?: boolean

  /** Ran anytime the active option(s) change. */
  onActiveChange?: (options: T[], e: React.SyntheticEvent<HTMLElement>) => void

  /** Callback invoked when the clea button is clicked */
  onClearActive?: () => void

  /** Callback invoked when the select loses focus */
  onBlur?: (e: React.FocusEvent<HTMLElement>) => void

  // ** Placholder string when no active options are set */
  placeholder?: string

  /**
   * Function to determine the rendering of the label
   * OR
   * Key within option object to use as the label
   */
  renderLabel:
  | keyof PickByValue<T, string>
  | ((option: T[]) => React.ReactNode)

  /**
   * Function to determine the rendering of the option
   * OR
   * Key within option object to use within the default optionItem
   */
  renderOption: OptionRenderer<T> | keyof PickByValue<T, string>
}

export interface GenericOption<T = any> {
  label: string
  value: T
}

export interface ISelectState {
  isOpen: boolean
}

export const Select = <T extends {} = GenericOption>(
  props: ISelectProps<T>,
) => {
  const optionListRef = React.useRef<OptionList<T>>(null)
  const offsetElement = React.useRef<HTMLElement>(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const [isSelectable, setIsSelectable] = React.useState(false)

  //
  // Actions
  //

  const blockOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
  }

  const clearSelect = (e: React.MouseEvent<HTMLElement>) => {
    safeInvoke(props.onClearActive)
    safeInvoke(props.onActiveChange, [], e)
  }

  function getOptionOffset(state: any) {
    let offset = 0

    if (offsetElement.current) {
      offset += state.reference.height / 2

      const elementOffset = offsetElement.current.offsetTop
      offset += offsetElement.current.clientHeight / 2
      offset += elementOffset
    } else {
      offset = state.referece?.height
    }

    return [0, -offset]
  }

  //
  // Handlers
  //

  const handlePopoverVisibilityChange = (nextOpenState: boolean) => {
    setIsOpen(nextOpenState)
  }

  const handlePopoverOpening = () => {
    let activeOption: T

    if (!optionListRef.current) return

    if (
      props.activeOptions &&
      props.activeOptions.length > 0 &&
      !props.allowMultiple
    ) {
      activeOption = props.activeOptions[0]
      offsetElement.current = optionListRef.current.getActiveElement()
    } else {
      activeOption = optionListRef.current.getFirstEnabledOption()
      offsetElement.current = optionListRef.current.getOptionElement(0)
    }

    optionListRef.current.setFocusOption(
      activeOption,
      optionListRef.current.scrollFocusedItemToTop,
    )

    // Set min width
    // if (!!targetRef.current && !!popover) {
    //   const width = targetRef.current.getBoundingClientRect().width
    //   popover.style.minWidth = width + 'px'
    // }
  }

  const handlePopoverResize = (popover: HTMLElement) => {
    // Set min width
    // if (!!targetRef.current && !!popover) {
    //   const width = targetRef.curent.getBoundingClientRect().width
    //   popover.style.minWidth = width + 'px'
    // }
  }

  const handleTargetBlur = (e: React.ChangeEvent<HTMLElement>) => {
    safeInvoke(props.onBlur, e)
  }

  const handleItemSelect = (
    option: T,
    e: React.SyntheticEvent<HTMLElement>,
  ) => {
    console.log('handle item select', isSelectable)
    if (!isSelectable) return

    safeInvoke(props.onOptionSelect, option, e)

    if (props.allowMultiple) {
      const activeOptions = xorBy(
        [option],
        props.activeOptions,
        props.optionIdProp,
      )
      safeInvoke(props.onActiveChange, activeOptions, e)
    } else {
      safeInvoke(props.onActiveChange, [option], e)
    }

    if (
      props.closeOnSelect === true ||
      (props.closeOnSelect === undefined && !props.allowMultiple)
    ) {
      setIsOpen(false)
      // if (!!targetRef.current) {
      //   targetRef.current.focus()
      // }
    }
  }

  const handleTargetKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    const optionList = optionListRef.current
    // open popover when arrow key pressed on target while closed
    if (
      event.which === keys.ARROW_UP ||
      event.which === keys.ARROW_DOWN ||
      event.which === keys.SPACE
    ) {
      setIsOpen(true)
    }
    if (event.which === keys.ESCAPE || event.which === keys.TAB) {
      setIsOpen(false)
    }

    // delegate key events to the option list
    if (!optionList) return
    optionList.handleKeyDown(event)
  }

  function handlePopoverOpened() {
    setIsSelectable(true)
  }

  function handlePopoverClosing() {
    setIsSelectable(false)
  }

  //
  // Render
  //

  // Default optionRenderer
  const renderOptionDefault: OptionRenderer<T> = (option, itemProps) => {
    if (isFunction(props.renderOption)) {
      return props.renderOption(option, itemProps)
    } else {
      return (
        <OptionItem
          key={itemProps.id}
          isActive={itemProps.modifiers.active}
          isDisabled={itemProps.modifiers.disabled}
          isFocused={itemProps.modifiers.focused}
          onClick={itemProps.handleClick}
          onMouseOver={itemProps.handleMouseEnter}
        >
          {option[props.renderOption]}
        </OptionItem>
      )
    }
  }

  const renderOptions = () => {
    const {
      placeholder,
      renderOption,
      onOptionSelect,
      ...optionListProps
    } = props
    return (
      <OptionList<T>
        className={cn({ '-multiple': props.allowMultiple })}
        {...optionListProps}
        renderOption={renderOptionDefault}
        onOptionSelect={handleItemSelect}
        ref={optionListRef}
      />
    )
  }

  const renderLabel = () => {
    const { activeOptions } = props
    const hasActiveItem = props.activeOptions && props.activeOptions.length > 0
    const classNames = cn(selectClassNames.LABEL, {
      '-placeholder': !hasActiveItem,
    })
    let label: React.ReactNode = ''

    if (hasActiveItem) {
      if (isFunction(props.renderLabel)) {
        label = props.renderLabel(activeOptions)
      } else if (activeOptions.length > 1) {
        label = [...activeOptions].reverse().map((option, index) => {
          if (isFunction(props.renderLabel)) return undefined // here so TS won't complain
          return (
            <span key={index}>
              {option[props.renderLabel]}
              {index + 1 === activeOptions.length ? null : ','}
              &nbsp;
            </span>
          )
        })
      } else {
        label = activeOptions[0][props.renderLabel]
      }
    }

    return (
      <span className={classNames}>
        {hasActiveItem ? label : props.placeholder}
      </span>
    )
  }

  const renderClear = () => {
    if (
      props.isDisabled ||
      !props.isClearable ||
      !props.activeOptions ||
      props.activeOptions.length === 0
    ) {
      return null
    }

    return (
      <Close
        className={selectClassNames.ADDON}
        shape={props.shape}
        appearance="fill"
        size={props.size}
        onMouseDown={blockOpen}
        onClick={clearSelect}
      />
    )
  }

  const { shape, intent, size, appearance: variant } = props

  const classNames = cn(
    selectClassNames.MAIN,
    getIntentClass(intent),
    getInputClasses({ shape, size, appearance: variant }),
    {
      '-required': props.isRequired,
      '-disabled': props.isDisabled,
    },
    props.className,
  )

  const modifiers = [
    {
      name: 'flip',
      enabled: false
    },
    {
      name: 'offset',
      options: {
        offset: getOptionOffset
      }
    },
    {
      name: 'preventOverflow',
      options:
      {
        altAxis: true,
      }
    }
  ]

  return (
    <Popup
      trigger={PopupTriggerType.MOUSEDOWN}
      autoFocus={false}
      className={selectClassNames.OPTIONS}
      isOpen={isOpen}
      isClosedOnEscape
      isMinimal
      isDisabled={props.isDisabled}
      onOpening={handlePopoverOpening}
      onOpened={handlePopoverOpened}
      onClosing={handlePopoverClosing}
      onResize={handlePopoverResize}
      onVisibilityChange={handlePopoverVisibilityChange}
      position="bottom"
      boundary={props.boundary}
      modifiers={modifiers}
      transitionDuration={[250, 0]}
      isLazy={false}
      motionProps={{
        transition: { duration: .25 },
        variants: {
          in: { opacity: 1, translateY: '0px' },
          out: { opacity: 0, translateY: '8px', transition: { duration: 0 } },
        },
        animate: isOpen ? 'in' : 'out',
        initial: 'out',
      }}
    >
      <div
        className={classNames}
        tabIndex={props.isDisabled ? undefined : 0}
        onKeyDown={handleTargetKeyDown}
        onBlur={handleTargetBlur}
      >
        {!!props.before ? (
          <div className={selectClassNames.ADDON}>{props.before}</div>
        ) : null}

        {/* Label / Placeholder */}
        {renderLabel()}

        {/* Dropdown Chevron */}
        <span className={cn(selectClassNames.ADDON, selectClassNames.ACTION)}>
          <Icon name={'CaretBottom'} />
        </span>

        {/* Clear button */}
        {renderClear()}
      </div>
      {renderOptions()}
    </Popup>
  )
}

Select.defaultProps = {
  isClearable: false,
  isDisabled: false,
  placeholder: 'Select',
  appearance: 'outline',
  shape: 'rounded',
  boundary: 'viewport',
}
