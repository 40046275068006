import { Enum } from '../library'

/**
 * Default density types for many components. Generally describes how much
 * space a component takes up, relatively speaking
 */
export const DensityType = Enum({
  COMPACT: 'compact',
  NORMAL: 'normal',
  RELAXED: 'relaxed'
})
export type DensityType = Enum<typeof DensityType>

/**
 * Default size types for many components. Typically in referece to the type
 * size, but other elements may be scaled to match
 */
export const SizeType = Enum({
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
})
export type SizeType = Enum<typeof SizeType>
