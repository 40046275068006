import React from 'react'
import cn from 'classnames'
import { clamp } from 'lodash'
import { motion, AnimatePresence } from 'framer-motion'

import { OverlayProps, OverlayStatus, OverlayState } from './overlay.types'
import { safeInvoke, keys, isArray } from '../../../library'
import { Portal } from '../../portal'

export const overlayClasses = {
  MAIN: 'overlay',
  CONTENT: 'overlay__content',
  CONTAINER: 'overlay-container',
  BACKDROP: 'overlay__backdrop',
  IS_OPEN: '-open',
  IS_INLINE: '-inline',
  IS_SCROLLABLE: '-scrollable',
  IS_BLOCKING: '-blocking',
  BODY_OPEN: 'overlay-open'
}

/**
 * Overlay
 */
export class Overlay extends React.Component<OverlayProps, OverlayState> {
  //
  // State
  //

  constructor(props: OverlayProps) {
    super(props)
    this.state = {
      hasEverOpened: props.isOpen,
      status: props.isOpen ? OverlayStatus.OPEN : OverlayStatus.CLOSED,
    }
  }

  containerRef = React.createRef<HTMLDivElement>()

  //
  // Static
  //

  static defaultProps = {
    autoFocus: true,
    backdropProps: {},
    isClosedOnEscape: true,
    isClosedOnOutsideClick: true,
    hasBackdrop: false,
    isOpen: false,
    isLazy: true,
    transitionDuration: 0,
    usePortal: true,
  }

  public static getDerivedStateFromProps({ isOpen: hasEverOpened }: OverlayProps) {
    if (hasEverOpened) {
      return { hasEverOpened }
    }
    return null
  }

  private static openStack: Overlay[] = []
  private static getLastOpened = () => Overlay.openStack[Overlay.openStack.length - 1]

  //
  // Lifecycle
  //

  componentDidMount() {
    if (this.props.isOpen) {
      this.overlayWillOpen()
    }
  }

  componentDidUpdate(prevProps: OverlayProps) {
    if (prevProps.isOpen && !this.props.isOpen) {
      this.overlayWillClose()
    } else if (!prevProps.isOpen && this.props.isOpen) {
      this.overlayWillOpen()
    }
  }

  componentWillUnmount() {
    this.overlayWillClose()
  }

  //
  // Actions
  //

  private get transition() {
    return isArray(this.props.transitionDuration)
      ? this.props.transitionDuration
      : [this.props.transitionDuration!, this.props.transitionDuration!] as const
  }

  bringFocusInsideOverlay = () => {
    // always delay focus manipulation to just before repaint to prevent scroll jumping
    return requestAnimationFrame(() => {
      // container ref may be undefined between component mounting and Portal rendering
      // activeElement may be undefined in some rare cases in IE
      if (
        this.containerRef.current == null ||
        document.activeElement == null ||
        !this.props.isOpen
      ) {
        return
      }


      const isFocusOutsideModal = !this.containerRef.current.contains(document.activeElement)
      if (isFocusOutsideModal) {
        // element marked autofocus has higher priority than the other clowns
        const autofocusElement = this.containerRef.current.querySelector(
          '[autofocus]',
        ) as HTMLElement
        const wrapperElement = this.containerRef.current.querySelector(
          '[tabindex]',
        ) as HTMLElement

        if (autofocusElement != null) {
          autofocusElement.focus()
        } else if (wrapperElement != null) {
          wrapperElement.focus()
        }
      }
    })
  }

  private overlayWillClose = () => {
    const { openStack } = Overlay
    const stackIndex = openStack.indexOf(this)

    this.setState({
      status: OverlayStatus.CLOSING,
    }, () => {
      safeInvoke(this.props.onClosing)
    })

    document.removeEventListener('focus', this.handleDocumentFocus, /* useCapture */ true)
    document.removeEventListener('mousedown', this.handleDocumentClick)


    if (stackIndex !== -1) {
      openStack.splice(stackIndex, 1)
      if (openStack.length > 0) {
        const lastOpenedOverlay = Overlay.getLastOpened()
        if (lastOpenedOverlay.props.enforceFocus) {
          document.addEventListener('focus', lastOpenedOverlay.handleDocumentFocus, /* useCapture */ true)
        }
      }

      // remove scroll prevention only if there are no open overlays with preventBodyScroll set
      if (Overlay.openStack.filter(o => o.props.preventBodyScroll).length === 0) {
        document.body.classList.remove(overlayClasses.BODY_OPEN)
      }
    }

    window.setTimeout(() => {
      this.overlayIsClosed()
    }, this.transition[1])
  }

  private overlayIsClosed = () => {
    this.setState({
      status: OverlayStatus.CLOSED,
    }, () => {
      safeInvoke(this.props.onClosed)
    })
  }

  private overlayWillOpen = () => {
    const { openStack } = Overlay

    // remove previous overlay focus listener so multiple overlays arent
    // fighting to enforce focus at the same time
    if (openStack.length > 0) {
      document.removeEventListener("focus", Overlay.getLastOpened().handleDocumentFocus, /* useCapture */ true)
    }

    this.setState({
      status: OverlayStatus.OPENING,
    }, () => {
      safeInvoke(this.props.onOpening)
    })

    openStack.push(this)

    if (this.props.autoFocus) {
      this.bringFocusInsideOverlay()
    }

    if (this.props.enforceFocus) {
      document.addEventListener('focus', this.handleDocumentFocus, /* useCapture */ true)
    }

    if (this.props.isClosedOnOutsideClick && !this.props.isBlocking) {
      document.addEventListener('mousedown', this.handleDocumentClick)
    }

    if (this.props.preventBodyScroll) {
      // add a class to the body to prevent scrolling of content below the overlay
      document.body.classList.add(overlayClasses.BODY_OPEN)
    }

    window.setTimeout(() => {
      this.overlayIsOpened()
    }, this.transition[0])

  }

  overlayIsOpened() {
    this.setState({
      status: OverlayStatus.OPEN,
    }, () => {
      safeInvoke(this.props.onOpened)
    })
  }

  //
  // Handlers
  //

  handleBackdropMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.props.isClosedOnOutsideClick) {
      safeInvoke(this.props.onClose, e)
    }

    if (this.props.enforceFocus) {
      // make sure document.activeElement is updated before bringing the focus back
      this.bringFocusInsideOverlay()
    }
    safeInvoke(this.props.backdropProps?.onMouseDown, e)
  }

  handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.which === keys.ESCAPE && this.props.isClosedOnEscape) {
      // prevent browser-specific escape key behavior (Safari exits fullscreen)
      e.preventDefault()
      safeInvoke(this.props.onClose, e)
    }
  }

  private handleDocumentClick = (e: MouseEvent) => {
    const { isClosedOnOutsideClick, isOpen, onClose } = this.props
    const eventTarget = e.target as HTMLElement

    const stackIndex = Overlay.openStack.indexOf(this)
    const isClickInThisOverlayOrDescendant = Overlay.openStack
      .slice(stackIndex)
      .some(({ containerRef: { current: elem } }) => {

        // `elem` is the container of backdrop & content, so clicking on that container
        // should not count as being "inside" the overlay.
        return elem && elem.contains(eventTarget) && !elem.isSameNode(eventTarget)
      })

    if (isOpen && isClosedOnOutsideClick && !isClickInThisOverlayOrDescendant) {
      // casting to any because this is a native event
      safeInvoke(onClose, e as any)
    }
  }

  private handleDocumentFocus = (e: FocusEvent) => {
    if (
      this.props.enforceFocus &&
      this.containerRef.current != null &&
      e.target instanceof Node &&
      !this.containerRef.current.contains(e.target as HTMLElement)
    ) {
      // prevent default focus behavior (sometimes auto-scrolls the page)
      e.preventDefault()
      e.stopImmediatePropagation()
      this.bringFocusInsideOverlay()
    }
  }

  //
  // Render
  //

  private maybeRenderBackdrop = () => {
    if (!this.props.isOpen || (!this.props.hasBackdrop && !this.props.isBlocking))
      return null

    const enter = clamp(this.transition[0] / 1000, 0, .35)
    const leave = clamp(this.transition[1] / 1000, 0, .35)

    return (
      <motion.div
        key="backdrop"
        {...this.props.backdropProps}
        initial={{ opacity: 0 }}
        animate={{
          opacity: this.props.hasBackdrop ? 1 : 0,
          transition: { duration: enter },
        }}
        exit={{
          opacity: 0,
          transition: {
            duration: leave,
            delay: (this.transition[1] / 1000) - leave,
          },
        }}
        className={cn(
          overlayClasses.BACKDROP,
          this.props.backdropClassName,
          this.props.backdropProps?.className,
        )}
        onMouseDown={this.handleBackdropMouseDown}
        tabIndex={this.props.isClosedOnOutsideClick ? 0 : undefined}
      />
    )
  }

  private maybeRenderChild = (child?: React.ReactChild) => {
    if (child == null) {
      return null
    }
    // add a special class to each child element that will automatically set the appropriate
    // CSS position mode under the hood. also, make the container focusable so we can
    // trap focus inside it (via `enforceFocus`).
    const decoratedChild =
      typeof child === 'object' ?
        (
          React.cloneElement(child, {
            className: cn(child.props.className, overlayClasses.CONTENT),
            tabIndex: 0,
          })
        ) : (
          <span className={overlayClasses.CONTENT}>{child}</span>
        )
    return decoratedChild
  }

  private maybeRenderContainer = () => {
    if (this.state.status === OverlayStatus.CLOSED) return null

    return React.Children.map(this.props.children, this.maybeRenderChild)
  }

  private renderOverlay = () => {
    const isBlocking = this.props.isBlocking !== undefined
      ? this.props.isBlocking
      : this.props.hasBackdrop

    const containerClasses = cn(
      overlayClasses.MAIN,
      `-${this.state.status}`,
      {
        [overlayClasses.IS_INLINE]: !this.props.usePortal,
        [overlayClasses.IS_BLOCKING]: isBlocking,
      },
      this.props.className,
    )

    return (
      <div
        className={containerClasses}
        onKeyDown={this.handleKeyDown}
        ref={this.containerRef}
      >
        <AnimatePresence>
          {this.maybeRenderBackdrop()}
        </AnimatePresence>
        {this.maybeRenderContainer()}
      </div>
    )
  }

  render() {
    // oh snap! no reason to render anything at all if we're being truly lazy
    if (this.props.isLazy && !this.state.hasEverOpened) {
      return null
    }

    if (this.props.usePortal) {
      return (
        <Portal
          className={this.props.portalClassName}
          container={this.props.portalContainer}
        >
          {this.renderOverlay()}
        </Portal>
      )
    }

    return this.renderOverlay()
  }
}
