import React from 'react'
import cn from 'classnames'

import { MaxWidthType, maxWidthClass } from '../../../library'
import { BaseProps } from '../../../types'

const containerClass = {
  CONTAINER: 'container',
}

export interface IContainerProps extends BaseProps {
  /**
   * Snap container to a fixed width at each breakpoint.
   */
  isFixed?: boolean

  /**
   * Set the max width of the container.
   */
  maxWidth?: MaxWidthType
}

export const Container: React.FC<IContainerProps> = props => {
  const { children, isFixed, maxWidth } = props
  const className = cn(
    containerClass.CONTAINER,
    { '-fixed': isFixed },
    maxWidthClass(maxWidth),
    props.className,
  )

  return <div className={className} children={children} />
}

Container.defaultProps = {
  isFixed: false,
}
