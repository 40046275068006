import React, { useRef, useContext, useState } from 'react'
import cn from 'classnames'
import useInView from 'react-cool-inview'

import {
  useFadeIn,
  UseFadeInOptions,
  FadeInAnimation,
  getFadeInClass
} from '../../../hooks/use-fade-in'
import { ensureElement } from '../../../library'


const FadeInContext = React.createContext<{ inView: boolean, animation: FadeInAnimation }>({
  inView: false,
  animation: FadeInAnimation.UP,
})


type ObserverProps = FadeInProps

type ChildProps = Pick<FadeInProps, 'animation' | 'tagName'> & {
  /**
   * Delay the enter animation in miliseconds
   */
  delay?: number
}


export interface FadeInProps extends UseFadeInOptions {
  /**
   * When supplied, will wrap the children in an outer element with the given tag
   * and apply observation to that. Necessary with custom coponents that do not
   * forward their ref.
  */
  tagName?: keyof JSX.IntrinsicElements
}

export type FadeIn = React.FC<FadeInProps> & {
  Child: typeof Child,
  Observer: typeof Observer,
}


const Observer: React.FC<ObserverProps> = (props) => {
  const TagName = props.tagName as 'div'
  const ref = useRef<HTMLDivElement>(null)
  const { inView } = useInView(ref, {
    unobserveOnEnter: true,
    rootMargin: props.rootMargin,
    threshold: props.threshold
  })

  // wrapped
  if (TagName !== undefined) {
    return (
      <FadeInContext.Provider value={{ inView, animation: props.animation }}>
        <TagName ref={ref}>{props.children}</TagName>
      </FadeInContext.Provider>
    )
  }

  // cloned
  const [target, ...rest] = React.Children.toArray(props.children)
  const safeTarget = ensureElement(target)
  const modifiedTarget = React.cloneElement(safeTarget, { ref })

  if (rest.length > 0) {
    console.warn('FadeIn: This component must only have one root child')
  }

  return (
    <FadeInContext.Provider value={{ inView, animation: props.animation }}>
      {modifiedTarget}
    </FadeInContext.Provider>
  )
}

Observer.defaultProps = {
  animation: FadeInAnimation.UP,
}

const Child: React.FC<ChildProps> = (props) => {
  const TagName = props.tagName as 'div'
  const { inView, animation: inheritedAnimation } = useContext(FadeInContext)
  const [isShown, setIsShown] = useState(inView)
  const classes = isShown ? getFadeInClass(props.animation ?? inheritedAnimation) : 'enter--pending'

  React.useEffect(() => {
    const timeout = window.setTimeout(() => {
      setIsShown(inView)
    }, props.delay)

    return () => {
      clearTimeout(timeout)
    }
  }, [inView])

  // wrapped
  if (TagName !== undefined) {
    return (
      <TagName className={classes}>{props.children}</TagName>
    )
  }

  // modified
  const [target, ...rest] = React.Children.toArray(props.children)
  const safeTarget = ensureElement(target)
  const modifiedTarget = React.cloneElement(safeTarget, {
    className: cn(
      safeTarget.props.className,
      classes,
    ),
  })


  if (rest.length > 0) {
    console.warn('FadeIn: This component must only have one root child')
  }

  return modifiedTarget
}

Child.defaultProps = {
  delay: 0,
}

export const FadeIn: FadeIn = (props) => {
  const TagName = props.tagName as 'div'
  const ref = useRef<HTMLDivElement>(null)
  const { classes } = useFadeIn(ref, props)

  // wrapped
  if (TagName !== undefined) {
    return (
      <TagName
        className={classes}
        ref={ref}
        children={props.children}
      />
    )
  }

  // cloned
  const [target, ...rest] = React.Children.toArray(props.children)
  const safeTarget = ensureElement(target)
  const classNames = cn(
    safeTarget.props.className,
    classes
  )
  const modifiedTarget = React.cloneElement(safeTarget, {
    ref,
    className: classNames,
  })

  if (rest.length > 0) {
    console.warn('FadeIn: This component must only have one root child')
  }

  return modifiedTarget
}

FadeIn.Observer = Observer
FadeIn.Child = Child
