import * as React from 'react';

/**
 * Converts a React child to an element: non-empty string or number or
 * `React.Fragment` (React 16.3+) is wrapped in given tag name; empty strings
 * are discarded.
 */
export function ensureElement(
  child: React.ReactNode | undefined,
  tagName: keyof JSX.IntrinsicElements = 'span',
): React.ReactElement {
  if (React.isValidElement(child)) {
    return child;
  } else if (typeof child === 'string') {
    // cull whitespace strings
    return child.trim().length > 0
      ? React.createElement(tagName, {}, child)
      : undefined;
  } else if (typeof child === 'number') {
    // React.Fragment has a symbol type
    return React.createElement(tagName, {}, child);
  } else {
    return React.createElement(tagName, {});
  }
}
