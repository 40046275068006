import React from 'react'
import cn from 'classnames'

import { BaseProps } from "../../../../types"
import { fieldClasses } from '../../field'

export interface FormControlGroupProps extends BaseProps {
  /**
   * Whether control components within the field should be conbined into a single group
   * @default false
  */
  isCombined?: boolean
}

export const Controls: React.FC<FormControlGroupProps> = props => {
  const className = cn(
    fieldClasses.CONTROLS,
    {
      '-combined': props.isCombined,
    },
    props.className,
  )

  return <div className={className}>{props.children}</div>
}

Controls.displayName = 'Controls'
Controls.defaultProps = {
  isCombined: false
}
