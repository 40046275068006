export const keys = {
  ALT: 18,
  ARROW_DOWN: 40,
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  ARROW_UP: 38,
  BACKSPACE: 8,
  CAPS_LOCK: 20,
  CONTROL: 17,
  DELETE: 46,
  ENTER: 13,
  ESCAPE: 27,
  META: 91,
  SHIFT: 16,
  SPACE: 32,
  TAB: 9,
};

// all keys that are not visible characters
export const metaKeys = [
  keys.ALT,
  keys.ARROW_DOWN,
  keys.ARROW_LEFT,
  keys.ARROW_RIGHT,
  keys.ARROW_UP,
  keys.BACKSPACE,
  keys.CAPS_LOCK,
  keys.CONTROL,
  keys.DELETE,
  keys.ENTER,
  keys.ESCAPE,
  keys.META,
  keys.SHIFT,
  keys.TAB,
];

/** Returns whether the key code is `enter` or `space`, the two keys that can click a button. */
export function isKeyboardClick(keyCode: number) {
  return keyCode === keys.ENTER || keyCode === keys.SPACE;
}
