import React, { useState, PropsWithChildren, createContext } from 'react'

import { CheckboxProps } from './checkbox'
import { InputSize } from '../../input'
import { noop } from 'lodash-es'
import { safeInvoke, Enum } from '../../../../library'

//
// Context
//

interface CheckboxGroupContextProps {
  size: InputSize
  isDisabled: boolean
  onUpdate: (field: string, value: boolean) => void
  isChecked: (value: any) => boolean
}

export const CheckboxGroupContext = createContext<CheckboxGroupContextProps>({
  size: InputSize.MEDIUM,
  isDisabled: false,
  onUpdate: noop,
  isChecked: () => false,
})

//
// Component
//

export const CheckboxGroupDirection = Enum({ HORIZONTAL: 'horizontal', VERTICAL: 'vertical' })
export type CheckboxGroupDirection = Enum<typeof CheckboxGroupDirection>

export type CheckboxGroupProps = Pick<CheckboxProps, 'size' | 'isDisabled'> & {
  onUpdate: (values: Record<string, boolean>) => void
  value?: Record<string, boolean>
  direction?: CheckboxGroupDirection,
}

export const CheckboxGroup: React.FC<CheckboxGroupProps> = (props) => {
  function handleCheckboxChange(field: string, value: boolean) {
    safeInvoke(props.onUpdate, { ...props.value, [field]: value })
  }

  function isChecked(field: string) {
    return props.value[field] === true
  }

  return (
    <div className="checkbox-group">
      <CheckboxGroupContext.Provider
        value={{
          size: props.size,
          isDisabled: props.isDisabled,
          onUpdate: handleCheckboxChange,
          isChecked,
        }}
      >
        {props.children}
      </CheckboxGroupContext.Provider>
    </div>
  )
}

CheckboxGroup.displayName = "CheckboxGroup"
CheckboxGroup.defaultProps = {
  value: {},
}
