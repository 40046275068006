import React from 'react'
import { findIndex, reject } from 'lodash-es'
import { v4 as uuid } from 'uuid'
import cn from 'classnames'

import { safeInvoke } from '../../../library'
import { BaseProps, Intent } from '../../../types'
import { paddingClass } from '../../../library/spacing'
import { AlertProps, AlertOptions } from './alert'
import { alertManager, AlertActionType } from './alert-manager'
import { Dialog, DialogPositionType, DialogSizeType } from '../../dialog'
import { Buttons, Button } from '../../button'
import { Heading } from '../../type/heading'

const alertClasses = {
  MAIN: 'alert',
}

export interface AlertContainerProps extends BaseProps {
  children?: never
}

export interface IAlertContainerState {
  alerts: AlertProps[]
  isOpen: boolean
}

export class AlertContainer extends React.Component<
  AlertContainerProps,
  IAlertContainerState
  > {
  //
  // static Props
  //

  static displayName = 'AlertContainer'

  state: IAlertContainerState = {
    alerts: [],
    isOpen: false,
  }

  //
  // Actions
  //

  show(options: AlertOptions, id?: string) {
    const alert = this._createAlertOptions(options, id)
    // if alert with id already exists AND is shown -> close it then replace it and re-open
    const curIndex = !!id ? findIndex(this.state.alerts, ['id', id]) : -1
    if (curIndex === 0) {
      this.dismiss(this.state.alerts[curIndex].id)
      window.setTimeout(() => {
        this.setState(prevState => {
          const filteredAlerts = reject(prevState.alerts, ['id', id])
          return {
            alerts: [alert, ...filteredAlerts],
            isOpen: true,
          }
        })
      }, 250)
      // ELSE IF alert exists but isnt shown -> just replace it
    } else if (curIndex > -1) {
      this.setState(prevState => {
        const filteredAlerts = reject(prevState.alerts, ['id', id])
        filteredAlerts.splice(curIndex, 0, alert)
        return {
          alerts: filteredAlerts,
          isOpen: true,
        }
      })
      // ELSE (a new alert) -> add to end of array
    } else {
      this.setState(prevState => {
        return {
          alerts: [...prevState.alerts, alert],
          isOpen: true,
        }
      })
    }

    return alert.id
  }

  async dismiss(id: string) {
    return new Promise(resolve => {
      const curIndex = findIndex(this.state.alerts, ['id', id])
      if (curIndex < 0) {
        resolve()
        return
      }

      let delay = 0
      if (curIndex === 0) {
        this.setState({ isOpen: false })
        delay = 250
      }
      window.setTimeout(() => {
        this.setState(prevState => {
          const updatedAlerts = reject(prevState.alerts, ['id', id])
          return {
            alerts: updatedAlerts,
            isOpen: updatedAlerts.length > 0,
          }
        }, resolve)
      }, delay)
    })
  }

  async clear() {
    // dismiss each id in reverse order ( so that active alert is removed last)
    // TODO: Since this is async (and takes a bit while waiting for a transition),
    // there is a small chance for issues if alerts are added while we are clearing.
    const alertIds = this.state.alerts.map(alert => alert.id).reverse()
    for (let index = 0; index < alertIds.length; index++) {
      await this.dismiss(alertIds[index])
    }
  }

  getAlerts() {
    return this.state.alerts
  }

  isAlertActive(id: string) {
    return this.state.alerts[0] && this.state.alerts[0].id === id
  }

  //
  // Helpers
  //

  _createAlertOptions(
    options: AlertOptions,
    id = `toast-${uuid()}`,
  ): AlertProps {
    const defaultProps: Partial<AlertOptions> = {
      cancelText: 'Cancel',
      confirmText: 'OK',
      intent: Intent.NONE,
      isCancelable: true,
      isClosedOnOutsideClick: false,
      isClosedOnEscape: false,
      position: DialogPositionType.CENTER,
      size: DialogSizeType.SM,
    }

    return { ...defaultProps, ...options, id }
  }

  _getDismissHandler = (alert: AlertProps) => () => {
    safeInvoke(alert.onCancel)
    this.dismiss(alert.id)
  }

  _getResolveHandler = (alert: AlertProps, fn?: () => void) => (
    e: React.MouseEvent,
  ) => {
    safeInvoke(fn)
    safeInvoke(alert.onResolve)
    this.dismiss(alert.id)
  }

  //
  // Handlers
  //

  //
  // Lifecycle
  //

  componentDidMount() {
    alertManager
      .on(AlertActionType.SHOW, ({ props, id }) => this.show(props, id))
      .on(AlertActionType.DISMISS, ({ id }) => this.dismiss(id))
      .on(AlertActionType.CLEAR, () => this.clear())
      .emit(AlertActionType.CONTAINER_MOUNT, { containerInstance: this })
  }

  componentWillUnmount() {
    alertManager
      .off(AlertActionType.SHOW)
      .off(AlertActionType.CLEAR)
      .off(AlertActionType.DISMISS)
      .emit(AlertActionType.CONTAINER_UNMOUNT, {})
  }

  //
  // Render
  //

  render() {
    const { alerts, isOpen } = this.state
    if (alerts.length === 0) return null

    const currentAlert = alerts[0]
    const classNames = cn(alertClasses.MAIN)
    const dismissHandler = this._getDismissHandler(currentAlert)

    return (
      <Dialog
        className={classNames}
        intent={currentAlert.intent}
        isClosedOnEscape={
          currentAlert.isClosedOnEscape || currentAlert.isCancelable
        }
        isClosedOnOutsideClick={
          currentAlert.isClosedOnOutsideClick && currentAlert.isCancelable
        }
        isOpen={isOpen}
        onClose={dismissHandler}
        position={currentAlert.position}
        showClose={false}
        size={currentAlert.size}
        preventBodyScroll={true}
      >
        <Dialog.Body className={cn(paddingClass([8]))}>
          {!!currentAlert.title ? (
            <Heading size={3} margin={[null, null, 4]} className={cn('c--jazzy')}>
              {currentAlert.title}
            </Heading>
          ) : null}
          {!!currentAlert.body ? (
            currentAlert.body
          ) : null}
        </Dialog.Body>
        <Dialog.Footer>
          <Buttons justify="end">
            {currentAlert.isCancelable ? (
              <Button
                appearance="minimal"
                intent="secondary"
                label={currentAlert.cancelText}
                onClick={dismissHandler}
              />
            ) : null}
            {currentAlert.actions ? (
              currentAlert.actions.map(action => (
                <Button
                  intent={
                    action.isPrimary
                      ? currentAlert.intent !== Intent.NONE
                        ? currentAlert.intent
                        : Intent.PRIMARY
                      : Intent.SECONDARY
                  }
                  appearance={action.isPrimary ? 'fill' : 'outline'}
                  label={action.label}
                  onClick={this._getResolveHandler(
                    currentAlert,
                    action.onClick,
                  )}
                />
              ))
            ) : (
                <Button
                  appearance="fill"
                  label={currentAlert.confirmText}
                  onClick={this._getResolveHandler(currentAlert)}
                  intent={
                    currentAlert.intent !== Intent.NONE
                      ? currentAlert.intent
                      : Intent.PRIMARY
                  }
                />
              )}
          </Buttons>
        </Dialog.Footer>
      </Dialog>
    )
  }
}
