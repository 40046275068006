import React, { useState, useRef, DOMAttributes, ReactNode, Fragment } from 'react'
import cn from 'classnames'

import { usePositioner } from '../../../hooks/use-positioner'
import {
  safeInvoke,
  ensureElement,
  elementIsOrContains,
} from '../../../library/index'


import { PopupProps, PopupTriggerType } from './popup.types'
import { positionToPlacement, getPosition } from './popup-utils'
import { PopupArrow } from './popup-arrow'
import { Overlay } from '../../overlay/index'
import { ResizeSensor } from '../../resize-sensor/src/resize-sensor'
import { motion } from 'framer-motion'
import { isPositionHorizontal, isPositionVertical } from '../../../types'
const warning = require('warning')

export const popupClasses = {
  TARGET: 'popup-target',
  WRAPPER: 'popup-wrapper',
  MAIN: 'popup',
  CONTENT: 'popup__content',
  ARROW: 'popup__pointer',
  BACKDROP: 'popup-backdrop',
  IS_OPEN: '-open',
  HAS_POINTER: '-pointer',
  DISMISS: 'popup-dismiss',
  DISMISS_OVERRIDE: 'popup-dismiss-override'
}

/**
 * Popover Component
 */
export const Popup: React.FC<PopupProps> = (props) => {
  //
  // State
  //
  const [isOpen, setIsOpen] = useState(props.defaultIsOpen)

  // Dom ELements
  const [referenceElementRef, setReferenceElementRef] = useState<HTMLDivElement>(null)
  const [popupElementRef, setPopupElementRef] = useState<HTMLElement>(null)

  // Internal Refs
  const _hideTimeout = useRef<number>()
  const _openTimeout = useRef<number>()

  const _lostFocusOnSamePage = useRef<boolean>(true)
  const _isMouseInTargetOrPopover = useRef<boolean>(false)

  const { popper, arrow, update, forceUpdate } = usePositioner(referenceElementRef, popupElementRef, {
    placement: positionToPlacement(props.position),
    modifiers: props.modifiers
  })

  //
  // Lifecycle
  //

  React.useEffect(() => {
    return () => {
      _clearScheduled()
    }
  }, [])

  //
  // Values
  //

  function getIsControlled(): boolean {
    return props.isOpen !== undefined
  }

  function getIsOpen(): boolean {
    return props.isDisabled
      ? false
      : getIsControlled()
        ? props.isOpen
        : isOpen
  }

  function getIsHoverTriggerKind(): boolean {
    return (
      props.trigger === PopupTriggerType.HOVER ||
      props.trigger === PopupTriggerType.HOVER_TARGET
    )
  }

  function getReferenceTriggerProps(): Partial<DOMAttributes<HTMLElement>> {
    const triggers: Partial<DOMAttributes<HTMLElement>> = {}

    switch (props.trigger) {
      case PopupTriggerType.MOUSEDOWN:
        triggers.onMouseDown = handleTargetClick
        break
      case PopupTriggerType.CLICK:
      case PopupTriggerType.CLICK_TARGET:
        triggers.onClick = handleTargetClick
        break
      case PopupTriggerType.HOVER:
      case PopupTriggerType.HOVER_TARGET:
        triggers.onMouseOver = handleMouseEnter
        triggers.onMouseLeave = handleMouseLeave
        triggers.onBlur = handleBlur
        triggers.onFocus = handleFocus
        break
    }
    return triggers
  }

  function getPopoverTriggerProps(): Partial<DOMAttributes<HTMLElement>> {
    const triggers: Partial<DOMAttributes<HTMLElement>> = {}

    triggers.onMouseUp = handlePopoverClick

    if (
      props.trigger === PopupTriggerType.HOVER ||
      (!props.usePortal && props.trigger === PopupTriggerType.HOVER_TARGET)
    ) {
      triggers.onMouseOver = handleMouseEnter
      triggers.onMouseLeave = handleMouseLeave
    }

    return triggers
  }

  function getIsElementInPopover(element: Element): boolean {
    return popupElementRef?.contains(element)
  }

  //
  // Actions
  //

  function _clearScheduled(): void {
    clearTimeout(_hideTimeout.current)
    clearTimeout(_openTimeout.current)
  }

  function openPopover(delay: number = 0, e?: React.SyntheticEvent<any>): void {
    _clearScheduled()

    _openTimeout.current = window.setTimeout(() => {
      safeInvoke(props.onVisibilityChange, true, e)
      setIsOpen(true)
    }, delay)
  }

  function closePopover(delay: number = 0, e?: React.SyntheticEvent<any>): void {
    _clearScheduled()

    _hideTimeout.current = window.setTimeout(() => {
      safeInvoke(props.onVisibilityChange, false, e)
      setIsOpen(false)
    }, delay)
  }


  //
  // Handlers
  //

  const handleTargetClick: React.MouseEventHandler = e => {
    if (props.isDisabled) return

    if (getIsOpen()) {
      closePopover(0, e)
    } else {
      openPopover(0, e)
    }
  }

  function handleReferenceResize(): void {
    safeInvoke(update)
    safeInvoke(props.onResize, popupElementRef)
  }

  function handlePopupResize(): void {
    safeInvoke(update)
  }

  function handlePopoverClick(e: React.MouseEvent<HTMLElement>): void {
    const eventTarget = e.target as HTMLElement
    // an OVERRIDE inside a DISMISS does not dismiss, and a DISMISS inside an OVERRIDE will dismiss.
    const dismissElement = eventTarget.closest(
      `.${popupClasses.DISMISS}, .${popupClasses.DISMISS_OVERRIDE}`
    )
    const shouldDismiss =
      dismissElement?.classList.contains(popupClasses.DISMISS)
    const isDisabled = eventTarget.closest(':disabled, .-disabled') != null

    if (shouldDismiss && !isDisabled && !e.isDefaultPrevented()) {
      closePopover(0, e)
      if (props.captureDismiss) {
        e.preventDefault()
      }
    }
  }

  function handleMouseEnter(e: React.SyntheticEvent<HTMLElement>): void {
    _isMouseInTargetOrPopover.current = true

    if (
      !props.usePortal &&
      getIsElementInPopover(e.target as Element) &&
      props.trigger === PopupTriggerType.HOVER_TARGET &&
      !props.isOpenedOnFocus
    ) {
      handleMouseLeave(e)
    } else if (!props.isDisabled) {
      // only begin opening popover when it is enabled
      openPopover(props.hoverOpenDelay, e)
    }
  }

  function handleMouseLeave(e: React.SyntheticEvent<HTMLElement>): void {
    _isMouseInTargetOrPopover.current = false

    window.setTimeout(() => {
      if (_isMouseInTargetOrPopover.current) {
        return
      }
      closePopover(props.hoverCloseDelay, e)
    })
  }

  const handleFocus: React.FocusEventHandler<HTMLElement> = e => {
    if (
      props.isOpenedOnFocus &&
      getIsHoverTriggerKind() &&
      !props.isDisabled
    ) {
      if (e.relatedTarget == null && !_lostFocusOnSamePage.current) {
        // ignore this focus event -- the target was already focused but the page
        // itself lost focus (e.g. due to switching tabs).
        return
      }
      handleMouseEnter(e)
    }
  }

  const handleBlur: React.FocusEventHandler<HTMLElement> = e => {
    if (
      props.isOpenedOnFocus &&
      getIsHoverTriggerKind() &&
      !props.isDisabled
    ) {
      // if the next element to receive focus is within the popover, we'll want
      // to leave the popover open.
      if (!getIsElementInPopover(e.relatedTarget as HTMLElement)) {
        handleMouseLeave(e)
      }
    }
    _lostFocusOnSamePage.current = e.relatedTarget != null
  }

  function handleOverlayClose(e: React.SyntheticEvent<HTMLElement>): void {
    const eventTarget = e.target as HTMLElement
    // if click was in target, target event listener will handle things, so don't close
    if (
      !elementIsOrContains(referenceElementRef, eventTarget) ||
      e.nativeEvent instanceof KeyboardEvent
    ) {
      closePopover(0, e)
    }
  }

  //
  // Render
  //

  function renderReferenceElement(child: ReactNode): React.ReactElement {
    const { targetTagName, targetProps = {}, ...restProps } = props
    const [rawTarget, ...rest] = React.Children.toArray(props.children)
    const safeReference = ensureElement(rawTarget)
    const referenceClasses = cn(
      popupClasses.TARGET,
      { [popupClasses.IS_OPEN]: isOpen },
      targetProps.className,
      props.targetClassName,
      safeReference.props.className
    )

    // # Force as a div, otherwise TS will error due to too many possibilities
    const TagName = targetTagName as 'div'

    const clonedTarget = React.cloneElement(safeReference, {
      className: referenceClasses,
      ...getReferenceTriggerProps(),
      ref: setReferenceElementRef
    })
    return (
      <ResizeSensor onResize={handleReferenceResize}>
        {TagName !== null ?
          (
            <TagName
              className={referenceClasses}
              ref={setReferenceElementRef}
              {...getReferenceTriggerProps()}
              children={rawTarget}
            />
          ) : (
            clonedTarget
          )
        }
      </ResizeSensor>
    )
  }

  const [reference, content] = React.Children.toArray(props.children)
  const position = getPosition(popper.placement)
  const x = isPositionVertical(position)
    ? position === 'left'
      ? -8
      : 8
    : 0
  const y = isPositionHorizontal(position)
    ? position === 'top'
      ? -8
      : 8
    : 0

  const defaultMotionProps = {
    transition: { duration: .25 },
    initial: { x, y, opacity: 0 },
    animate: { x: 0, y: 0, opacity: getIsOpen() ? 1 : 0 },
  }
  const motionProps = props.motionProps || defaultMotionProps

  return (
    <Fragment>
      {renderReferenceElement(reference)}

      <Overlay
        autoFocus={props.autoFocus}
        backdropClassName={popupClasses.BACKDROP}
        backdropProps={props.backdropProps}
        isClosedOnEscape={props.isClosedOnEscape}
        isClosedOnOutsideClick={props.trigger === PopupTriggerType.CLICK || props.trigger === PopupTriggerType.MOUSEDOWN}
        className={props.portalClassName}
        hasBackdrop={props.hasBackdrop}
        isBlocking={props.isBlocking}
        isOpen={getIsOpen()}
        isScrollable={props.isScrollable}
        onClose={handleOverlayClose}
        onClosed={props.onClosed}
        onClosing={props.onClosing}
        onOpened={props.onOpened}
        onOpening={props.onOpening}
        portalContainer={props.portalContainer}
        usePortal={props.usePortal}
        isLazy={props.isLazy}
        transitionDuration={props.transitionDuration}
      >
        <div
          className={cn(popupClasses.WRAPPER, props.className)}
          ref={setPopupElementRef}
          style={popper.styles}
          {...popper.attributes}
          {...getPopoverTriggerProps()}
          tabIndex={props.autoFocus ? 0 : null}
        >
          <motion.div
            className={cn(popupClasses.CONTENT, props.popupClassName)}
            {...motionProps}
          >
            {content}
            {!props.isMinimal && <PopupArrow style={arrow.styles} />}
          </motion.div>
        </div>
      </Overlay>
    </Fragment>
  )
}

//
// Defaults
//
Popup.displayName = 'Popup'

Popup.defaultProps = {
  boundary: 'viewport',
  captureDismiss: false,
  content: null,
  defaultIsOpen: false,
  hasBackdrop: false,
  hoverCloseDelay: 150,
  isBlocking: false,
  isClosedOnEscape: true,
  isDisabled: false,
  isOpenedOnFocus: true,
  isMinimal: false,
  isScrollable: false,
  modifiers: [],
  position: 'top',
  targetTagName: null,
  transitionDuration: 250,
  trigger: PopupTriggerType.CLICK,
  usePortal: true
}
