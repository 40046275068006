import React from 'react'
import cn from 'classnames'

import { fieldClasses } from '../../field'
import { ColumnProps } from '../../../column'
import { responsiveClasses } from '../../../../library'

export interface IFormControlProps extends ColumnProps { }

export const Control: React.FC<IFormControlProps> = props => {
  const { align, offset, order, span } = props
  const className = cn(
    fieldClasses.CONTROL,
    align ? responsiveClasses('-%s', align) : null,
    offset ? responsiveClasses('-offset-%s', offset) : null,
    order ? responsiveClasses('-order-%s', order) : null,
    span ? responsiveClasses('-span-%s', span) : null,
    props.className,
  )

  return <div className={className}>{props.children}</div>
}

Control.displayName = 'Control'
Control.defaultProps = {}
