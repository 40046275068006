import cn from 'classnames';

import { ResponsiveProp, Responsive } from '../types';
import { breakpointInfix, Breakpoint } from './breakpoints';
import { isArray } from './validators'

type spacingProp = number | null | 'auto';

function isSpacingProps(prop: any): prop is spacingProp {
  return typeof prop === 'number' || prop === 'auto' || prop === null;
}

function isResponsiveProp<T>(prop: any): prop is ResponsiveProp<T> {
  return typeof prop === 'object' && !Array.isArray(prop);
}

function unpackShorthand<T = any>(
  ...args: T[]
): [T, T, T, T] | [] {
  switch (args.length) {
    case 0:
      return [];
    case 1:
      return [args[0], args[0], args[0], args[0]];
    case 2:
      return [args[0], args[1], args[0], args[1]];
    case 3:
      return [args[0], args[1], args[3], args[1]];
    case 4:
    default:
      return [args[0], args[1], args[2], args[3]];
  }
}

interface shorthandReturn {
  all: spacingProp;
  vert: spacingProp;
  horz: spacingProp;
  top: spacingProp;
  bottom: spacingProp;
  right: spacingProp;
  left: spacingProp;
}

function convertShorthand(...args: spacingProp[]): shorthandReturn {
  let props: Partial<shorthandReturn> = {};
  let defaultProps: shorthandReturn = {
    all: null,
    vert: null,
    horz: null,
    top: null,
    bottom: null,
    right: null,
    left: null,
  };

  switch (args.length) {
    case 0:
      props = {};
      break;
    case 1:
      props = { all: args[0] };
      break;
    case 2:
      props = { vert: args[0], horz: args[1] };
      break;
    case 3:
      props = { top: args[0], horz: args[1], bottom: args[2] };
      break;
    case 4:
    default:
      props = { top: args[0], right: args[1], bottom: args[2], left: args[3] };
      break;
  }

  return { ...defaultProps, ...props };
}

export function spacingClass(
  type: 'padding' | 'margin',
  args: ResponsiveProp<spacingProp[]> | Array<Responsive<spacingProp> | spacingProp>,
) {
  let typeCode = '';
  let props: Responsive<spacingProp[]> = {
    xs: [],
    sm: [],
    md: [],
    lg: [],
    xl: [],
  };

  switch (type) {
    case 'padding':
      typeCode = 'p';
      break;
    case 'margin':
      typeCode = 'm';
      break;
  }

  if (isArray(args)) {
    Object.values(Breakpoint).forEach((bp) => {
      args.forEach((arg) => {
        if (isSpacingProps(arg)) {
          props[bp].push(bp === 'xs' ? arg : null)
        } else {
          props[bp].push(arg[bp] ?? null)
        }
      })
    });
  } else {
    props = args;
  }

  const classes = Object.keys(props).map((breakpoint: Breakpoint) => {
    const { all, vert, horz, top, left, bottom, right } = convertShorthand(
      ...props[breakpoint],
    );
    const infix = breakpointInfix(breakpoint);
    return cn({
      [`${typeCode}--${all}${infix}`]: all !== null,
      [`${typeCode}--y-${vert}${infix}`]: vert !== null,
      [`${typeCode}--x-${horz}${infix}`]: horz !== null,
      [`${typeCode}--t-${top}${infix}`]: top !== null,
      [`${typeCode}--r-${right}${infix}`]: right !== null,
      [`${typeCode}--b-${bottom}${infix}`]: bottom !== null,
      [`${typeCode}--l-${left}${infix}`]: left !== null,
    });
  });

  return cn(classes);
}

export function paddingClass(
  args: ResponsiveProp<spacingProp[]> | Array<Responsive<spacingProp> | spacingProp>,
): string {
  return spacingClass('padding', args);
}

export function marginClass(
  args: ResponsiveProp<spacingProp[]> | Array<Responsive<spacingProp> | spacingProp>,
) {
  return spacingClass('margin', args);
}
