import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Menu } from "../partials"
import { AlertContainer } from "../components"
import { BaseProps } from "../types"

import "../styles/app.scss"


export interface LayoutProps extends BaseProps { }

export const Layout: React.FC<LayoutProps> = (props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Menu />
      <main className={props.className}>
        {props.children}
      </main>
      <AlertContainer />
    </>
  )
}

export default Layout
