import { RefObject } from 'react'
import useInView from 'react-cool-inview'
import cn from 'classnames'

import { Enum } from '../library'

export const FadeInAnimation = Enum({
  UP: 'fade-in-up',
  DOWN: 'fade-in-down',
  DEFAULT: 'fade-in',
})
export type FadeInAnimation = Enum<typeof FadeInAnimation>

export interface UseFadeInOptions {
  threshold?: number
  rootMargin?: string
  animation?: FadeInAnimation
}

export const useFadeIn = (ref: RefObject<HTMLElement | null>, options?: UseFadeInOptions) => {
  const { inView } = useInView(ref, {
    unobserveOnEnter: true,
    threshold: options?.threshold || 0,
    rootMargin: options?.rootMargin,
  })
  const animation = options?.animation ?? FadeInAnimation.UP

  return {
    inView,
    classes: cn(inView ? getFadeInClass(animation) : 'enter--pending'),
  }
}

export function getFadeInClass(animation: FadeInAnimation) {
  return `enter--${animation}`
}
